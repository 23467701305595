

export const webstore_search = [
    {
        heading: "Private webstore",
        key: "ws_self_login_required",
        section: "general"
    },

    {
        heading: "Treat non-approved customers as anonymous users",
        key: "ws_use_whitelist",
        section: "general"
    },

    {
        heading: "Visible only when customer is logged in",
        key: "ws_login_required",
        section: "general"
    },

    {
        heading: "Login Prompt",
        key: "ws_login_required_message",
        section: "general"
    },


    {
        heading: "Allow customers to view their lists at any Bookmanager site",
        key: "ws_allow_list_transfer",
        section: "general"
    },

    {
        heading: "Enable automatic browse redirection",
        key: "ws_auto_browse_destination",
        section: "general"
    },

    {
        heading: "Available to Order wording",
        key: "ws_order_wording",
        section: "ordering"
    },

    {
        heading: "Available On Hand wording",
        key: "ws_instore_instock",
        section: "pricing"
    },

    {
        heading: "Cover display wording",
        key: "ws_wording_hide_cover_nis_so",
        section: "pricing"
    },


    {
        heading: "Use custom On Hand conditions",
        key: "ws_use_onhand_conditions",
        section: "pricing"
    },

    {
        heading: "Hide listing and cart for regular (new) titles if non-regular stock exists at the same price",
        key: "ws_hide_matching_new_price_condition",
        section: "pricing"
    },

    {
        heading: "Alternate home page URL",
        key: "ws_homeurl",
        section: "general"
    },

    {
        heading: "Landing page URL",
        key: "ws_landing",
        section: "general"
    },

    {
        heading: "Enable Terms & Conditions",
        key: "ws_terms_consent_enabled",
        section: "general"
    },

    {
        heading: "Privacy Policy Page",
        key: "ws_policy_page",
        section: "general"
    },

    {
        heading: "Contact Us Page",
        key: "ws_custom_contact_page",
        section: "general"
    },

    {
        heading: "Street address In footer",
        key: "ws_hide_footer_address",
        section: "general"
    },

    {
        heading: "Social media",
        key: "ws_facebookurl",
        section: "general"
    },

    {
        heading: "Event sharing control",
        key: "ws_social_media_facebook_shareable",
        section: "general"
    },

    {
        heading: "Libro.fm Audiobook Integration",
        key: "ws_libro_bookstore_name",
        section: "general"
    },

    {
        heading: "Display carton quantity below Add to Cart",
        key: "ws_display_carton_quantity",
        section: "general"
    },

    // {
    //     heading: "View on Webstore sends to Biz",
    //     key: "bm_use_biz_url",
    //     section: "general"
    // },

    {
        heading: "Display Government of Canada funding acknowledgement",
        key: "ws_government_of_canada_logo_enabled",
        section: "general"
    },


    // ACCOUNTS

    // {
    //     heading: "Email verification",
    //     key: "ws_email_auth",
    //     section: "accounts"
    // },

    {
        heading: "Email notification",
        key: "ws_new_acct_notify",
        section: "accounts"
    },

    {
        heading: "Custom required fields in account creation form",
        key: "ws_new_acct_inputs",
        section: "accounts"
    },

    {
        heading: "Override welcome message",
        key: "ws_custom_welcome_msg",
        section: "accounts"
    },

    // ORDERING

    {
        heading: "Enable online ordering",
        key: "enable_online_ordering",
        section: "ordering"
    },

    {
        heading: "Orders email address",
        key: "order_email",
        section: "ordering"
    },

    {
        heading: "Enable cart abandonment reminder email",
        key: "ws_cart_abandonment_email_enabled",
        section: "ordering"
    },

    {
        heading: "Display estimated delivery date on special orders",
        key: "ws_show_estimated_delivery",
        section: "ordering"
    },

    {
        heading: "Submitted orders will auto generate a Held Sale in Bookmanager",
        key: "spool_order_files",
        section: "ordering"
    },

    {
        heading: "Adjust online On Hand quantities when orders are placed by customers",
        key: "ws_adjust_onhand_with_orders",
        section: "ordering"
    },

    {
        heading: "My Pubstock suppliers must have stock on hand (or NYR) to be considered a source of supply.",
        key: "ws_no_cart_unless_onhand",
        section: "ordering"
    },


    {
        heading: "Hide customer cart for ISBNs that are Out Of Stock (OS) or Out of Stock Indefinitely (OSI), except when you have it on On Hand or On Order.",
        key: "ws_no_cart_osi",
        section: "ordering"
    },

    {
        heading: "Do not remove ISBNs that are Out Of Stock, Out Of Stock Indefinitely, or Out Of Print from a customer's cart",
        key: "ws_allow_order_osi",
        section: "ordering"
    },


    {
        heading: "Allow Quote Checkout",
        key: "ws_quotes_enabled",
        section: "ordering"
    },

    {
        heading: "Allow Guest Checkout",
        key: "ws_guest_checkout",
        section: "ordering"
    },

    {
        heading: "When a customer is not logged in",
        key: "ws_hide_cart_price_if_anon",
        section: "ordering"
    },


    {
        heading: "Show order inscription option",
        key: "ws_show_order_inscription_field",
        section: "ordering"
    },


    {
        heading: "Allow in-Store pick up",
        key: "ws_delivery_pickup_enabled",
        section: "ordering"
    },


    {
        heading: "Checkout message for all orders",
        key: "ws_message_checkout",
        section: "ordering"
    },


    {
        heading: "Custom confirmation email message for picked up orders",
        key: "ws_message_pickup",
        section: "ordering"
    },

    {
        heading: "Custom confirmation email message for picked up orders",
        key: "ws_message_pickup",
        section: "ordering"
    },

    {
        heading: "Custom Ship Checkout Button",
        key: "active_custom_ship",
        section: "ordering"
    },

    {
        heading: "Custom Pickup Checkout Button",
        key: "active_custom_pickup",
        section: "ordering"
    },

    // SHIPPING

    {
        heading: "Enable local delivery",
        key: "ws_local_delivery_enabled",
        section: "shipping"
    },


    {
        heading: "Local delivery message",
        key: "ws_message_local_cart",
        section: "shipping"
    },


    {
        heading: "Local delivery postal or ZIP codes",
        key: "ldc_local_delivery_code",
        section: "shipping"
    },


    {
        heading: "Find postal codes",
        key: "ldc_local_find_postal_codes",
        section: "shipping"
    },


    {
        heading: "Local delivery message",
        key: "ws_delivery_local_availability",
        section: "shipping"
    },


    {
        heading: "Local delivery threshold and fees",
        key: "ws_delivery_local_fee",
        section: "shipping"
    },


    {
        heading: "Order total must exceed",
        key: "ws_delivery_local_minimum",
        section: "shipping"
    },


    {
        heading: " Enable free local delivery over a set amount",
        key: "ws_delivery_local_free_enabled",
        section: "shipping"
    },


    {
        heading: "Free delivery if order total exceeds",
        key: "ws_delivery_local_free",
        section: "shipping"
    },


    {
        heading: "Enable shipping",
        key: "ws_delivery_ship_enabled",
        section: "shipping"
    },


    {
        heading: "Shipping cost exception list",
        key: "ws_shipping_base_rate_ignore_csv",
        section: "shipping"
    },


    {
        heading: "Shipping message (Before checkout)",
        key: "ws_message_ship_cart",
        section: "shipping"
    },


    {
        heading: "Shipping message (After checkout)",
        key: "ws_message_ship",
        section: "shipping"
    },


    {
        heading: "Enable batch shipping",
        key: "ws_batch_shipping",
        section: "shipping"
    },


    {
        heading: "Multiship",
        key: "multiship",
        section: "shipping"
    },


    {
        heading: "Shipping cost calculator",
        key: "cost_calculator",
        section: "shipping"
    },


    // Searching

    {
        heading: "Title search results will sort by default",
        key: "default_ts_sort",
        section: "searching"
    },

    {
        heading: "Filter options",
        key: "ws_hide_no_stock",
        section: "searching"
    },

    {
        heading: "Enable BM Class for consumers",
        key: "ws_enable_classes",
        section: "searching"
    },

    {
        heading: "Class filters header",
        key: "ws_bm_class_heading",
        section: "searching"
    },

    // Pricing

    {
        heading: "Add strikethrough to list prices",
        key: "ws_strikethrough",
        section: "pricing"
    },
    
    {
        heading: "Enable sales promotions & promo codes",
        key: "using_promo_codes",
        section: "pricing"
    },

    {
        heading: "Online price calculation for orders",
        key: "ws_price_estimate_strategy",
        section: "pricing"
    },

    {
        heading: "Customer discounts",
        key: "ws_online_discount",
        section: "pricing"
    },

    {
        heading: "Minimum profit margin",
        key: "ws_minimum_margin",
        section: "pricing"
    },

    {
        heading: "Display list prices",
        key: "ws_show_ca_us_list_prices",
        section: "pricing"
    },

    {
        heading: "Hide sale pricing from product details page",
        key: "ws_hide_sale_pricing",
        section: "pricing"
    },


    // Payments

    {
        heading: "Enable pay at pick up",
        key: "ws_accepts_payment_pickup",
        section: "payments"
    },

    {
        heading: "Enable contact for payment",
        key: "ws_accepts_payment_contact",
        section: "payments"
    },

    {
        heading: "Enable PayPal payments",
        key: "ws_accepts_paypal",
        section: "payments"
    },

    {
        heading: "Enable in-store credit card processing",
        key: "ws_credit_card_in_store",
        section: "payments"
    },

    {
        heading: "Disallow debit credit cards",
        key: "ws_disallow_cc_debit",
        section: "payments"
    },


    // Multi-Store

    {
        heading: "Each store location has an individual webstore",
        key: "ws_nonconsolidated_multistore",
        section: "multistore"
    },

    {
        heading: "Contact information for orders",
        key: "contact_information",
        section: "multistore"
    },

    // SEO

    {
        heading: "Meta tags",
        key: "ws_metadesc",
        section: "seo"
    },

    {
        heading: "Third party codes",
        key: "ws_google_analytics_ua",
        section: "seo"
    },

    // Advanced

    {
        heading: "Custom head and body additions",
        key: "ws_head_custom_text",
        section: "advanced"
    },

    
    // Title Manager

    {
        heading: "Blocklist for Searching and Browsing",
        key: "blocklist_for_searching_and_browsing",
        section: "titles"
    },
    {
        heading: "Allowlist for Searching and Browsing",
        key: "allowlist_for_searching_and_browsing",
        section: "titles"
    },
    {
        heading: "Zero price list",
        key: "zero_price_list",
        section: "titles"
    },
    {
        heading: "Pay at Pick Up Blocklist",
        key: "pay_at_pick_up_blocklist",
        section: "titles"
    },
    {
        heading: "Blocklist for Shipping",
        key: "blocklist_for_shipping",
        section: "titles"
    },

     // Impulse
 
     {
        heading: "Impulse Purchase Pop-Up",
        key: "impulse_purchase_pop_up",
        section: "impulse"
    },

    {
        heading: "Enable Impulse Purchase For In-Store Pick Up / Guest Checkout",
        key: "enable_impulse_purchase_for_in_store_pick_up_guest_checkout",
        section: "impulse"
    },

    {
        heading: "Enable Impulse Purchase For Ship / Local Delivery",
        key: "enable_impulse_purchase_for_ship_local_delivery",
        section: "impulse"
    },

    // Apps

    // {
    //     heading: "Connect Bookmanager with QuickBooks",
    //     key: "connect_bookmanager_with_quickBooks",
    //     section: "app_integrations"
    // },

    

]

