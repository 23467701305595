import { FacebookFilled, TwitterSquareFilled } from '@ant-design/icons';
import { Button, Divider, Input, message, Space, Typography } from "antd";
import React, { useState } from "react";

import { useSession } from "../../utils/Session";
import BroadcastMessage from "../BroadcastMessage";
import { XIcon } from '../icons/XIcon';

export default function ListShare(props) {

    const { close = () => { }, list_id = "" } = props;
    const [session, setSession] = useSession();
    const [copyText, setCopyText] = useState("Copy");

    const url = window.sitesettings.url + "lists/" + list_id;
    const url2 = "https://share.bookmanager.com/biz/purchasing/browse/filter/x/"+list_id+"/v/sequence";

    
    const CopyButton = (props) => {
        const { path = "" } = props
        const [copied, setCopied] = useState(false);

        const copyToClipboard = (_str) => {
            if (!navigator.clipboard) {
                message.error("Can't copy");
                return;
            }
            navigator.clipboard.writeText(path);
            setCopied(true);
            setTimeout(() => {
                setCopied(false)
            }, 1000);
        }
        return (<Button onClick={(e) => copyToClipboard(e)} type={(copied ? "default" : "primary")}>{(copied) ? "Copied" : "Copy"}</Button>);
    }

    const copyLink = () => {
        navigator.clipboard.writeText(url);
        setCopyText("Copied")
    }

    return (
        <>
            <div style={{ "padding": "25px" }}>
            <Typography.Title level={5}>Share</Typography.Title>
                <Typography.Paragraph>To share this list with <strong>customers</strong>, copy this link (e.g. Ctrl-C) and then paste it into an email.</Typography.Paragraph>
                <Space>
                    <Input style={{ "width": "400px" }} defaultValue={url} />
                    <CopyButton path={url} />
                </Space>
                <div className="shim" /><div className="shim" /><div className="shim" />
                <Typography.Paragraph>To share this list with reps or other <strong>bookmanager stores</strong>, copy this link (e.g. Ctrl-C) and then paste it into an email.</Typography.Paragraph>
                <Space>
                    <Input style={{ "width": "400px" }} defaultValue={url2} />
                    <CopyButton path={url2} />
                </Space>
                <div className="shim" /><div className="shim" /><div className="shim" />
                <BroadcastMessage title="Send list to staff member screen" path={url2} />
                <div className="shim" /><div className="shim" /><div className="shim" />
                <Typography.Paragraph>Share on social media</Typography.Paragraph>
                
                <Space size={5}>
                    <a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/share?url=" + url}><Button type="text" icon={<XIcon style={{ "fontSize": "32px", "color": "#000" }} />} /></a>
                    <a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + url}><Button type="text" icon={<FacebookFilled style={{ "fontSize": "32px", "color": "#4267B2" }} />} /></a>
                </Space>
                <Divider style={{ "margin": "15px 0px" }} />
                <div style={{ "float": "right" }}>
                    <Space>
                        <Button onClick={(e) => close()}>Cancel</Button>
                    </Space>
                </div>
                <br clear="all" />
            </div>
        </>
    );
}