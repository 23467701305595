import React, { useState } from "react";
import { Row, Col, Card, Space, Typography, Button, Divider, message, Popconfirm } from "antd";
import { CopyOutlined, ShareAltOutlined, FilePdfOutlined, EditOutlined } from '@ant-design/icons';
import Checkbox from "antd/lib/checkbox/Checkbox";
import { apiCall } from "../../utils/Api";
export default function TitlePDF(props) {

    const {isbn} = props; 

    //isbn=9780735237759&show_barcode=true&show_marketing=true

    const [showBarcode, setShowBarcode] = useState(false);
    const [showMarketing, setShowMarketing] = useState(false);
    const [visible, setVisible] = useState(false)

    const generatePDF = () =>{

        let _f = {
            show_barcode : showBarcode,
            show_marketing : showMarketing,
            isbn : isbn
        }

        apiCall("title/getPdf", _f, (_status, _result) =>{
            if(_status){
                window.open("/pdf/" + _result.progress_report_id, "_blank");
                setVisible(false);
            } else {
                message.error(_result.error);
                setVisible(false);
            }
        })


    }

    const drawForm = () => {
        return (
            <>
                <div style={{"fontWeight" : "500"}}>Create PDF</div>
                <Divider dashed style={{"margin" : "10px 0px"}} />
                <Space direction="vertical">
                    <Checkbox checked={showBarcode} onChange={() => setShowBarcode(!showBarcode)}>Show Barcode?</Checkbox>
                    <Checkbox checked={showMarketing} onChange={() => setShowMarketing(!showMarketing)}>Show Marketing?</Checkbox>
                </Space>
                <div className="shim" />
                
            </>
        )
    }

    return (
        <Popconfirm
            overlayClassName="popover-noicon"
            title={drawForm()}
            onConfirm={() => generatePDF()}
            okText="Generate"
            cancelText="Cancel"
            onVisibleChange={(e) => setVisible(e)}
            icon={null}
            overlayStyle={{"padding" : "0px"}}
        >
            <Button onClick={() => setVisible(!visible)} style={{ "width": "26px" }} size="small" className="mini-btn" ><small><FilePdfOutlined /></small></Button>
        </Popconfirm>
    )



}