import React, { useEffect, useState } from "react";
import RRule from "./RRule";
import EventCategorySelector from "./EventCategorySelector";
import { Button, Divider, Form, Input, Radio, Space, Spin, Switch, Tabs, Typography, message } from "antd";
import TextEditor from "../utilities/TextEditor";
import moment from "moment";
import TitleManager from "../../pages/settings/TitleManager";
import ImageSelect from "../../utils/ImageSelect";
import Tickets from "./Tickets";
import RSVP from "./RSVP";
import { apiCall } from "../../utils/Api";


export default function EventItem(props) {

    const { close = () => { }, updateRow = () => { }, create = false, categories = [], setCategories = () => { }, is_new = false } = props;
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState({});
    const [attend, setAttend] = useState("unrestricted");
    const fetchEvent = () => {

        if(is_new){
            setLoading(false);
            return; 
        }

        setLoading(true);
        apiCall("events/v2/get", { id: props.event.id }, (_status, _result) => {
            if (_status) {
                setEvent(_result);
                setAttend(_result.attendance)
            } else {
                message.error(_result.error)
            }
            setLoading(false);
            
        })
    }

    useEffect(fetchEvent, [])

    const {
        id = "",
        title = "",
        description = "",
        category = "",
        summary = "",
        tickets = [],
        date = "",
        start_time = "09:00:00",
        end_time = "17:00:00",
        all_day = false,
        rrule = "",
        image_url = "",
        enabled = true,
        books = [],
        attendance = "unrestricted",
        location_text = "",
        ticket_label = "Buy Tickets",
        rsvp = [],
        rsvp_label = "RSVP",
        rsvp_limit = 0,
        waitlist_enabled = false,
        email_enabled = false,
        auto_purge = false,
        rsvp_email_recipient = "",
        rsvp_per_day = false,
        recurrence_dates = [],
        recurring_as_single_row = 0,

    } = event;


    const [hasRRule, setRRule] = useState(rrule);
    

    const uglyTime = (_str) => {
        const time = moment(_str, 'h:mm A', true);
        if (!time.isValid()) {
            return '';
        }
        return time.format('HH:mm');
    }

    const prettyTime = (_str) => {
        if(!_str){
            return "";
        }
        const time = moment(_str, 'HH:mm:ss').format('h:mm A');
        return time;
    };


    const saveEvent = (_f) => {

        _f["tickets"] = (_f["tickets"]) ? JSON.stringify(_f["tickets"]) : "";
        _f["rsvp"] = (_f["rsvp"]) ? JSON.stringify(_f["rsvp"]) : "";
        _f["books"] = (_f["books"]) ? JSON.stringify(_f["books"].map(item => item.isbn)) : "";
        _f["date"] = (_f["date"] instanceof moment) ? _f["date"].format("YYYY-MM-DD") : "";
        _f["start_time"] = uglyTime(_f["start_time"]);
        _f["end_time"] = uglyTime(_f["end_time"]);


        if(_f["rsvp_limit"] === null || _f["rsvp_limit"] === ""){
            _f["rsvp_limit"] = 0;
        }

        if (create) {
            addEvent(_f);
        } else {
            _f["id"] = id;
            editEvent(_f);
        }
    }

    const addEvent = (_f) => {
        apiCall("events/v2/add", _f, (_status, _result) => {
            if (_status) {
                updateRow();
                message.success("Event created successfully!")
                close();
            } else {
                message.error(_result.error);
            }
        })
    }

    const editEvent = (_f) => {
        apiCall("events/v2/edit", _f, (_status, _result) => {
            if (_status) {
                updateRow(_result, true);
                message.success("Event updated successfully!")
                close();
            } else {
                message.error(_result.error)
            }
        })
    }

    if (loading) {
        return (
            <Spin spinning >
                <div style={{ "width": "700px", "height": "600px", "padding": "30px" }}></div>
            </Spin>
        )
    }

    return (
        <div style={{ "width": "700px", "padding": "30px" }}>

            <Typography.Title level={5}>{(create) ? "Create new event" : "Edit event"}</Typography.Title>
            <Divider />
            <Form form={form} onFinish={(_f) => saveEvent(_f)} layout="vertical">
                <div className="shim" /><div className="shim" /><div className="shim" />
                <Space>
                    <Form.Item initialValue={title} rules={[{ required: true, "message": "Title is required" }]} name="title" label="Event Title">
                        <Input placeholder="Title..." style={{ "width": "302px" }} />
                    </Form.Item>
                    <Form.Item initialValue={category} hidden name="category">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Category">
                        <EventCategorySelector setCategories={setCategories} categories={categories} onChange={(e) => form.setFieldsValue({ "category": e })} category={category} />
                    </Form.Item>
                    <Space>
                        <div style={{ "width": "15px" }}>&nbsp;</div>
                        <Form.Item initialValue={enabled} valuePropName="checked" name="enabled" label="Event visible?">
                            <Switch size="small" />
                        </Form.Item>
                    </Space>
                </Space>
                <Form.Item initialValue={location_text} name="location_text" label="Location">
                        <Input placeholder="Location..." style={{ "width": "302px" }} />
                    </Form.Item>
                <Form.Item hidden initialValue={rrule} name="rrule" noStyle>
                </Form.Item>
                <RRule start_time={prettyTime(start_time, false)} date={(date) ? moment(date) : moment()} end_time={prettyTime(end_time, false)} recurring_as_single_row={recurring_as_single_row} all_day={all_day} form={form} rule={rrule} onChange={(e) =>{ setRRule(e); form.setFieldsValue({ "rrule": e })}} />
                <Form.Item initialValue={description} hidden name="description">
                    <Input />
                </Form.Item>
                <Tabs className="extraTabs itemTabs" size="small" type="card">
                    <Tabs.TabPane key="summary" tab={<small>Summary</small>} >
                        <Form.Item initialValue={summary} name="summary">
                            <Input.TextArea maxLength={1000} showCount placeholder="Summary..." style={{ "fontSize": "12px" }} rows={8} />
                        </Form.Item>
                        <small className="c">The Summary description of an event will be displayed in two instances: for the brief event listing found beside the event calendar, and on the complete list of events generated by the Events List component (which is placed by default on the built-in Events menu). <div className="shim" /><div className="shim" />If the Details tab of an event is blank, then the Summary will be displayed when "Read More..." is selected. The Summary is plain text only, whereas Details allow for text formatting, hyperlinks, and images.</small>
                        <div className="shim" /><div className="shim" />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="details" tab={<small>Details</small>} >
                        <TextEditor height={300} value={description} onChange={(e) => { form.setFieldsValue({ description: e }) }} />
                        <small>The Details description of an event will be displayed if no Summary description exists. If a Summary is specified, the Details description will be displayed when the customer selects "Read More..." that appears at the bottom of the Summary description.</small>
                        <div className="shim" /><div className="shim" />
                    </Tabs.TabPane>
                </Tabs>
                <Form.Item initialValue={image_url} hidden name="image_url">
                    <Input />
                </Form.Item>
                <Space size={30}>
                    <TitleManager hide_header bottom={true} form={form} form_key={"books"} titles={books.map(item => {
                        return {
                            isbn: item.isbn,
                            eisbn: item.eisbn,
                            title: item.title
                        }
                    })} title={<span style={{ "fontSize": "14px", "fontWeight": "normal" }}>Titles</span>} />
                    <div>
                        <div className="shim" style={{ "height": "3px" }} />
                        <label>Event Poster/Author Image</label>
                        <ImageSelect path={image_url} onChange={(e) => form.setFieldsValue({ image_url: e })} />
                    </div>
                </Space>
                <Divider dashed />
                <label>Attendance &nbsp;&nbsp;&nbsp;</label>
                <Form.Item initialValue={attend} name="attendance">
                    <Radio.Group onChange={(e) => setAttend(e.target.value)} size="small" buttonStyle="solid">
                        <Radio.Button value={"unrestricted"}><small>&nbsp;Unrestricted&nbsp;</small></Radio.Button>
                        <Radio.Button value={"purchase"}><small>&nbsp;Purchase <em>(Tickets)</em>&nbsp;</small></Radio.Button>
                        <Radio.Button value={"request"}><small>&nbsp;Request <em>(RSVP)</em>&nbsp;</small></Radio.Button>
                    </Radio.Group>
                </Form.Item>
                {(attend === "purchase" && <>
                    <Form.Item hidden initialValue={tickets} name="tickets">
                        <Input />
                    </Form.Item>
                    <Space size={30}>
                        <Tickets onChange={(e) => form.setFieldsValue({ tickets: e })} tickets={tickets} />
                        <Form.Item initialValue={ticket_label} label="Ticket Button Label" name="ticket_label">
                            <Input />
                        </Form.Item>
                    </Space>
                </>)}
                {(attend === "request" && <>
                    <RSVP date={date} recurrence_dates={recurrence_dates} rsvp_per_day={rsvp_per_day} rrule={hasRRule} rsvp_email_recipient={rsvp_email_recipient} auto_purge={auto_purge} event_id={id} rsvp={rsvp} email_enabled={email_enabled} waitlist_enabled={waitlist_enabled} rsvp_limit={rsvp_limit} rsvp_label={rsvp_label} />
                </>)}
                <Divider dashed />
                <div className="float-flex">
                    <div></div>
                    <Space>
                        <Button onClick={() => close()} type="default">Cancel</Button>
                        <Button type="primary" htmlType="submit">Save</Button>
                    </Space>
                </div>
            </Form>

        </div>
    )

}