import React, { useState } from "react";
import { Popover, Image, Upload, Modal, Table, Badge, Input, Form, Space, message, Spin, Button, Popconfirm } from "antd";
import nocover from "../../media/no-cover.png"
import { apiCall } from "../../utils/Api";
import { UploadOutlined, GlobalOutlined, DeleteOutlined } from '@ant-design/icons';
export default function CoverImage(props) {


    const { isbn, eisbn, editing = false, cache = "", cover_path = "/no-cover.png" } = props;
    const [visible, setVisible] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [valid, setValid] = useState(true);




    const open = () => {
        setValid(true);
        setVisible(true);
    }

    const deleteImage = () => {

        apiCall("title/deleteCoverImage", { isbn: isbn }, (_status, _result) => {
            if (_status) {
                setVisible(false);
                message.success("Cover image delete requested.");
            } else {
                message.error(_result.error);
            }
            setUploading(false);
        });

    }

    const uploadFile = (_file) => {
        setUploading(true);
        apiCall("title/uploadCoverImage", { file: _file.file, isbn: isbn }, (_status, _result) => {
            if (_status) {
                message.success("Cover image update requested.");
            } else {
                message.error(_result.error)
            }
            setUploading(false);
        });
    }


    const copyFileFromUrl = (_f) => {

        apiCall("title/uploadCoverImageByUrl", { url: _f["url"], isbn: isbn }, (_status, _result) => {
            if (_status) {
                setVisible(false);
                message.success("Cover image update requested.");
            } else {
                setValid(false);
            }
            setUploading(false);
        });

    }


    const drawEdit = () => {


        if (uploading) {
            return (<Spin />)
        }

        return (
            <>
                <Space direction="vertical" size={15}>
                    <Upload customRequest={(e) => uploadFile(e)} accept=".jpg, .jpeg, .png"  >
                        <span style={{ "color": "#fff" }}><UploadOutlined /> <small>Upload</small></span>
                    </Upload>

                    <Modal destroyOnClose footer={null} closeIcon={<></>} visible={visible}>
                        <Form className={(!valid) ? "ant-form-item-has-error" : ""} onFinish={(_f) => copyFileFromUrl(_f)} layout="vertical">
                            <Form.Item help={<small style={{ color: (!valid) ? "#ff4d4f" : "#AAA" }}>Use a direct link to image</small>} rules={[{ required: true }]} name="url" label="Image URL">
                                <Input type="url" placeholder="Image URL" />
                            </Form.Item>

                            <span style={{ "float": "right" }}>
                                <Space>
                                    <Button onClick={() => setVisible(false)}>Cancel</Button><Button htmlType="submit" type="primary">Copy</Button>
                                </Space>
                            </span>
                            <br clear="all" />
                        </Form>
                    </Modal>
                    <span onClick={() => open()} style={{ "color": "#fff" }}><GlobalOutlined /> <small>URL</small></span>


                    <span onClick={() => Modal.confirm({ onOk: () => deleteImage(), content: "Are you sure you wish to delete this image?" })} style={{ "color": "#fff" }}><DeleteOutlined /> <small>Remove</small></span>

                </Space>
            </>
        )
    }



    return (
        <>
            <Image className="shadow-large" preview={{ visible: false, mask: (editing) ? drawEdit() : false }} onError={(e) => e.target.src = cover_path} placeholder={""} src={"https://cdn1.bookmanager.com/mosaic/i/m?b=" + eisbn + cache} />
        </>
    )

}