import React from "react";
import { Form, Typography, Divider, Space, Radio, Checkbox, InputNumber } from "antd";
import WebstoreItem from "../WebstoreItem";
export default function PricingSection(props) {

    const { ws } = props;


    const origin = (window.sitesettings.is_canadian) ? "Canadian" : "American";
    const currency_type = (window.sitesettings.is_canadian) ? "CAD" : "USD";


    return (
        <>
            <div id="pricing" style={{ "padding": "10px 30px" }}>
                <Typography.Title level={4}>Pricing & Stock</Typography.Title>
                <Divider />

                {/* Available On Hand wording: */}
                <div id="ws_instore_instock"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Available On Hand wording:</Typography.Title>
                        <Typography.Paragraph type="secondary">Choose the wording shown for titles that you have available On Hand (aOH). "In stock" may be preferable for webstores that don't have storefronts.</Typography.Paragraph>
                        <Form.Item initialValue={(ws["global:ws_instore_instock"]) ? true : false} noStyle name="global:ws_instore_instock">
                            <Radio.Group>
                                <Radio value={false}>in store</Radio>
                                <div className="shim"></div>
                                <Radio value={true}>in stock</Radio>
                                <div className="shim"></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Space>

                <Divider dashed />
                {/* Cover display wording: */}
                <div id="ws_wording_hide_cover_nis_so"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Cover display wording:</Typography.Title>
                        <Typography.Paragraph type="secondary">Choose to display "not in stock" when no onhand or onorder and "sold out" when onorder for the cover display.</Typography.Paragraph>
                        <Form.Item initialValue={(ws["global:ws_wording_hide_cover_nis_so"]) ? true : false} noStyle name="global:ws_wording_hide_cover_nis_so">
                            <Radio.Group>
                                <Radio value={false}>Show</Radio>
                                <div className="shim"></div>
                                <Radio value={true}>Hide</Radio>
                                <div className="shim"></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Space>
                <Divider dashed />

                {/* Use custom On Hand conditions */}
                <div id="ws_use_onhand_conditions"></div>
                <WebstoreItem
                    name="global:ws_use_onhand_conditions"
                    value={(ws["global:ws_use_onhand_conditions"]) ? true : false}
                    heading="Use custom On Hand conditions"
                    description={<div>Current condition codes:<br />

                        {window.sitesettings.condition_codes.map(item => {
                            if (item.code) {
                                return <>{item.code}: {item.name} <br /></>
                            }
                        })}
                        <br />If you have enabled custom On Hand conditions (bargain, used, signed, etc.) in your Bookmanager software, checking this box will allow those books to be visible on your Webstore</div>}
                />

<Divider dashed />
                <div id="ws_hide_matching_new_price_condition"></div>
                {/* Hide listing and cart for regular (new) titles if non-regular stock exists at the same price */}
                <WebstoreItem
                    name="global:ws_hide_matching_new_price_condition"
                    value={(ws["global:ws_hide_matching_new_price_condition"]) ? true : false}
                    heading="Hide listing and cart for regular (new) titles if non-regular stock exists at the same price"
                    description={<div>When this box is unchecked, the consumer can choose from any of multiple stock conditions having the same price to add to their cart. When checked, the regular condition cart will be hidden only if there are non-regular condition books available for the new price. An example use of this option would be if you sell signed or promotional books for the same price as a new book, but don't want customers to have the option to buy regular (new) unsigned books.</div>}
                />
                <Divider dashed />

                {/* Stock */}
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Stock</Typography.Title>
                        <Form.Item initialValue={ws["global:ws_show_qty"]} noStyle name="global:ws_show_qty">
                            <Radio.Group>
                                <Radio value={2}>Show stock quantities to customers</Radio>
                                <div className="shim"></div>
                                <Radio value={1}>Show 25+ if the quantity is over 25</Radio>
                                <div className="shim"></div>
                                <Radio value={0}>Don't show stock quantities <div style={{ "lineHeight": "12px", "color": "#f00" }}><small>If you enable this setting and hide stock quantities from your site, customers <strong><em>will</em></strong> be able to order more than what you have on hand or on order.</small></div></Radio>
                                <div className="shim"></div>
                                <Radio value={3}>Don't show stock quantities, In Store/Stock Now, or On Order indicators. Instead, all titles on hand, on order, and available to order will display 'Available to order' or 'Special Order'.</Radio>
                                <div className="shim"></div>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Space>

                <Divider dashed />

                {/* Minimum profit margin */}
                <div id="ws_minimum_margin"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Minimum profit margin:&nbsp;
                            <Form.Item initialValue={ws["global:ws_minimum_margin"]} noStyle name="global:ws_minimum_margin">
                                <InputNumber max={100} precision={0} />
                            </Form.Item>
                        </Typography.Title>
                        <Typography.Paragraph type="secondary">
                            This setting is a fallback for when a Pubstock <strong>vendor code</strong> has been enabled for consumer Webstore use (the code is set to <strong>Yes</strong> or <strong>Fav</strong> in the Bookmanager software), but you have yet to add that code to the <strong>3 Suppliers</strong> area in Bookmanager. This setting only takes effect in that rare case, and if the title being quoted to a customer for order has a short discount.
                        </Typography.Paragraph>
                        <Typography.Paragraph type="secondary">
                            Set this value to 1% lower than your main Currency Code's <strong>SDMargin</strong> is set in Bookmanager. For example, if your US Currency Code in Bookmanager has an SDMargin set to 25%, then you should set this online Minimum Profit Margin field to 24%. Even if there are exceptions to this rule, in the case where you have a special utility Currency Code with an SDMargin of 0, you should still set a non-zero fallback for the webstore, one percent lower than your general default SDMargin. Setting your online Minimum Profit Margin to the same value or higher than your software's default SDMargin can result in inconsistent sell prices between Bookmanager and the consumer Webstore.
                        </Typography.Paragraph>
                    </div>
                </Space>

                <Divider dashed />

                {/* Minimum Order */}
                <div id="order_min"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Minimum order subtotal:&nbsp;
                            <Form.Item initialValue={ws["global:order_min"]} noStyle name="global:order_min">
                                <InputNumber precision={2} />
                            </Form.Item>
                        </Typography.Title>
                        <Typography.Paragraph type="secondary">Checkout will be disabled if order subtotal does not meet or exceed this threshold.</Typography.Paragraph>
                    </div>
                </Space>

                <Divider dashed />

                {/* Display list prices */}
                <div id="ws_show_ca_us_list_prices"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Display list prices</Typography.Title>
                        <Typography.Paragraph type="secondary">Enable if you would like to display either the US or Canadian list prices (or both) to your customers alongside your sell prices. List prices will be displayed while browsing and on the product details page. This option is usually for wholesalers or those selling below list price for the majority of their stock. <em>Not all titles have list prices provided to us from publishers.</em></Typography.Paragraph>
                        <Form.Item initialValue={ws["global:ws_show_ca_us_list_prices"]} name="global:ws_show_ca_us_list_prices" noStyle>
                            <Radio.Group>
                                <Radio value="both">Both</Radio>
                                <div className="shim" />
                                <Radio value="usa">US</Radio>
                                <div className="shim" />
                                <Radio value="can">CAN</Radio>
                                <div className="shim" />
                                <Radio value="none">None</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Space>

                <Divider dashed />

                {/* Add strikethrough to list prices */}
                <div id="ws_strikethrough"></div>
                <WebstoreItem
                    name="global:ws_strikethrough"
                    value={(ws["global:ws_strikethrough"]) ? true : false}
                    heading="Add strikethrough to list prices"
                    description={<div>When checked, a strike-through will be displayed over the list price when the available sell price is lower than the list price. This will act as a visual cue to customers to help show they are not paying the higher list price.</div>}
                />

                <Divider dashed />

                {/* Private webstore */}
                <div id="ws_hide_sale_pricing"></div>
                <WebstoreItem
                    name="global:ws_hide_sale_pricing"
                    value={(ws["global:ws_hide_sale_pricing"]) ? true : false}
                    heading="Hide sale pricing from product details page"
                    description={<div>Enable this to hide the automatic "You save" sale price comparison text that is displayed when viewing the product details page for items that have a sell price below your original / list price. Most stores should not enable this option, as letting customers know their savings for items that are selling below the original / list price is desired and draws attention to items on sale.</div>}
                />

                <Divider dashed />

                {/* Online price calculation for orders */}
                <div id="ws_price_estimate_strategy"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Online price calculation for orders</Typography.Title>
                        <Typography.Paragraph type="secondary">Choose how pricing will be determined for product that is not On Hand but can be ordered. These rules are used to choose which vendor pricing and availability to use when there are multiple sources to choose from. For example, if a product is available from both a US and a Canadian source, both of which use different currency exchanges and markups, these rules determine which source will be quoted.</Typography.Paragraph>
                        <Form.Item initialValue={ws["global:ws_price_estimate_strategy"]} noStyle name="global:ws_price_estimate_strategy">
                            <Radio.Group>
                                <Radio value={0}>
                                    Least price aggressive
                                    <Typography.Paragraph type="secondary">Show the customer the lowest {currency_type} list price, else lowest “after markup” price, and then the expected delivery time associated with the supplier having the lowest cost. Even if the product is available cheaper from a foreign source, your {origin} sources will be given priority.</Typography.Paragraph>
                                </Radio>
                                <div className="shim"></div>
                                <Radio value={2}>
                                    More Price Aggressive
                                    <Typography.Paragraph type="secondary">Show the customer the lowest “after markup” price and the expected delivery time associated with the supplier having the lowest cost. Regardless of currency or source, your customer will be quoted the lowest price that conforms to your markup and profit margin rules set for that supplier.</Typography.Paragraph>
                                </Radio>
                                <div className="shim"></div>
                                <Radio value={3}>
                                    Prioritize delivery time
                                    <Typography.Paragraph type="secondary">Show the customer the fastest delivery time and price associated with the fastest delivery time, which may result in a lower profit margin or a higher price. In most cases the fastest source will also be able to quote the lowest (or same) price, so this option may seem redundant. However, if a book is available from multiple sources and at different prices, costs and delivery times, you need to decide what to offer customers. Price is important but so is time. For example, a wholesaler may be able to deliver a book faster but at a short discount, which will result in a low margin and/or a higher price if you also markup prices to reflect a minimum margin. Use the fastest delivery time option if you consider speed to be the most important factor.</Typography.Paragraph>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Space>

                <Divider dashed />


                {/* Add strikethrough to list prices */}
                <div id="ws_strikethrough"></div>
                <WebstoreItem
                    name="global:using_promo_codes"
                    value={(ws["global:using_promo_codes"]) ? true : false}
                    heading="Enable sales promotions &amp; promo codes"
                    description={<div>When checked, the website will automatically add any sales and promotions as well as let visitors enter promo codes.</div>}
                />

                <Divider dashed />


                {/*Customer discounts */}
                <div id="ws_online_discount"></div>
                <Space>
                    <div style={{ "width": "35px" }}></div>
                    <div>
                        <Typography.Title level={5}>Customer discounts</Typography.Title>
                        <Typography.Text><strong>Online discount </strong>
                            <Form.Item initialValue={ws["global:ws_online_discount"]} noStyle name="global:ws_online_discount">
                                <InputNumber formatter={(value) => parseInt(value) || 0} min={0} max={100} precision={0} />
                            </Form.Item>
                        </Typography.Text>
                        <Typography.Paragraph type="secondary">The online price quoted for customers will use your selection from the Online Pricing option, less this discount, but only if the discounted price yields at least the minimum profit margin value. This setting is particularily useful if you have a special "Web-only" type of promotion.</Typography.Paragraph>
                        <Form.Item valuePropName='checked' initialValue={(ws["global:ws_cust_disc_flags"].discount_new) ? true : false} name={["global:ws_cust_disc_flags", "discount_new"]} noStyle>
                            <Checkbox>Use customer's discount stored in Bookmanager for new books...
                                <br />
                                <Form.Item valuePropName='checked' initialValue={(ws["global:ws_cust_disc_flags"].discount_new_except_nt) ? true : false} name={["global:ws_cust_disc_flags", "discount_new_except_nt"]} noStyle>
                                    <Checkbox>except if the the title's discount is NT</Checkbox>
                                </Form.Item>
                            </Checkbox>
                        </Form.Item>
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                        <Form.Item valuePropName='checked' initialValue={(ws["global:ws_cust_disc_flags"].discount_non_new) ? true : false} name={["global:ws_cust_disc_flags", "discount_non_new"]} noStyle>
                            <Checkbox>Use customer's discount stored in Bookmanager for non-new books with condition codes...
                                <br />
                                <Form.Item valuePropName='checked' initialValue={(ws["global:ws_cust_disc_flags"].discount_non_new_except_nt) ? true : false} name={["global:ws_cust_disc_flags", "discount_non_new_except_nt"]} noStyle>
                                    <Checkbox>except if the the title's discount is NT</Checkbox>
                                </Form.Item>
                            </Checkbox>
                        </Form.Item>
                    </div>
                </Space>


                <Divider dashed />






            </div>
        </>
    )
}