import React, { useEffect, useState } from "react";
import { Layout, Typography, Divider, Button, Space, DatePicker, Statistic, Input, Form, Table, Modal, Popconfirm, message, Card, Badge, Select } from "antd";
import moment from "moment";
import { apiCall } from "../../utils/Api";
import { RollbackOutlined } from '@ant-design/icons';
import { addCommas, pathToObj } from "../../utils/Utils";
import { useHistory, useParams } from "react-router-dom";
import Paginate from "../../components/Paginate";
import PreviousOrder from "../../components/PreviousOrder";
import Overlay from "../../components/Overlay";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import { useSession } from "../../utils/Session";

export default function CustomForms() {

    const [results, setResults] = useState({ row_count: 0, rows: [] });
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false)
    const [session, setSession] = useSession(); 
    const history = useHistory();


    const getActiveAt = (_type = "pickup") => {

        let _f = results.rows.find(item => item.active_at.includes(_type));
        if (_f) {
            return _f.id
        } else {
            return "";
        }

    }

    const listAll = () => {
        setLoading(true);
        apiCall("settings/custom_forms/listAll", {}, (_status, _result) => {
            if (_status) {
                setResults(_result);


            } else {
                message.error(_result.error)
            }
            setLoading(false)
        })
    }

    useEffect(listAll, [])

    const assignOrderForm = (_where, _id) => {
        apiCall("settings/custom_forms/assignOrderForm", { where: _where, form_id: _id }, (_status, _result) => {
            if (_status) {
                message.success("Assigned");
                listAll();
            } else {
                message.error(_result.error)
            }
        })
    }
    // /store/settings/custom_forms/assignOrderForm

    const deleteForm = (_id) => {

        apiCall("settings/custom_forms/delete", { form_id: _id }, (_status, _result) => {
            if (_status) {
                message.success("Form deleted successfully");
                listAll()
            } else {
                message.error(_result.error)
            }
        })
    }

    const columns = [

        // {
        //     title: "", dataIndex: 'status', key: 'enabled', "width": "55px", render: (e, f) => {
        //         if (e) {
        //             return (<Badge style={{ "paddingLeft": "7px" }} status="success" />);
        //         } else {
        //             return (<Badge style={{ "paddingLeft": "7px" }} status="default" />);
        //         }
        //     }
        // },



        {
            title: <small>Header</small>, width: 300, ellipsis: true, dataIndex: 'header', key: 'header'
        },

        {
            title: <small>Description</small>, ellipsis: true, dataIndex: 'description', key: 'description'
        },

        {
            title: <small>Edit / Delete</small>, width: 125, dataIndex: 'edit', key: 'edit', render: (e, f) => {

                return (
                    <Space>
                        <Overlay width={500} component={<DisplayForm isnew={false} data={f} />}><Button size="small" type="primary"><small>Edit</small></Button></Overlay>
                        <Popconfirm onConfirm={() => deleteForm(f.id)} title="Are you sure?">
                            <Button type="primary" danger size="small"><small>Delete</small></Button>
                        </Popconfirm>
                    </Space>)
            }
        },

        {
            title: <small>Builder</small>, width: 110, dataIndex: 'actions', key: 'actions', render: (e, f) => {
                return (
                    <Space>
                        <Button onClick={() => history.push("/content/custom_form/" + f.id)} type="primary" size="small"><small>Form Builder</small></Button>
                    </Space>
                )
            }
        },

    ]

    const setForm = (_f, _new = false) => {

        let _action = (_new) ? "settings/custom_forms/create" : "settings/custom_forms/set"

        let _args = {
            form_header: _f.header,
            form_description: _f.description
        }
        if(!_new){
            _args.form_id = _f.form_id;
        }

        apiCall(_action, _args, (_status, _result) => {
            if (_status) {
                listAll();
                setVisible(false);
                if(_new){
                    message.success("Form created successfully!")
                } else {
                    message.success("Form updated successfully!")
                }
                
            } else {
                message.error(_result.error)
            }
        })
    }

    const drawOptions = () => {
        return [{ label: "No form", value: "" }].concat(results.rows.map(item => {
            return { label: item.header, value: item.id }
        }))
    }

    const DisplayForm = (props) => {


        const { isnew = false, data = {} } = props;
        let _header = (isnew) ? "Create form" : "Update form";

        const {
            header = "",
            description = ""
        } = data; 

        return (
            <div style={{ "padding": "25px" }}>
              
                <Typography.Title level={4}>{_header}</Typography.Title>
                <Divider />
                <Form onFinish={(_f) => {

                    if (isnew) {
                        setForm(_f, isnew);
                        props.close();

                    } else {
                        setForm({ ..._f, "form_id": data.id }, isnew);
                        props.close();
                    }

                }} layout="vertical">
                    <Form.Item rules={[{required : true, message : "Header is required."}]} initialValue={header} name="header" label="Header">
                        <Input placeholder="Header..." />
                    </Form.Item>

                    <Form.Item rules={[{required : true, message : "Description is required."}]} initialValue={description} name="description" label="Description">
                        <Input.TextArea rows={4} placeholder="Description..." maxLength={1000} showCount />
                    </Form.Item>
                    <div className="shim" /><div className="shim" />
                    <div className="float-flex">
                        <div>&nbsp;</div>
                        <Space>
                            <Button onClick={() => props.close()}>Cancel</Button>
                            <Button htmlType="submit" type="primary">{_header}</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        )

    }



    return (
        <>

            <Layout className="layout">

                <LayoutHeader
                    title="Custom forms"
                    description={<>
                        <div>Use this page to create forms for use in customer checkout.</div>
                        <Divider dashed />

                        <Space>
                            <div>
                                <div><label>Pick Up</label></div>
                                <Select onSelect={(e) => assignOrderForm("pickup", e)} value={getActiveAt("pickup")} style={{ "width": "200px" }} size="small" options={drawOptions()} />
                            </div>
                            <div>
                                <div><label>Ship</label></div>
                                <Select onSelect={(e) => assignOrderForm("ship", e)} value={getActiveAt("ship")} style={{ "width": "200px" }} size="small" options={drawOptions()} />
                            </div>
                            <div>
                                <div><label>Custom Pick Up</label></div>
                                <Select onSelect={(e) => assignOrderForm("custom_pickup", e)} value={getActiveAt("custom_pickup")} style={{ "width": "200px" }} size="small" options={drawOptions()} />
                            </div>
                            <div>
                                <div><label>Custom Ship</label></div>
                                <Select onSelect={(e) => assignOrderForm("custom_ship", e)} value={getActiveAt("custom_ship")} style={{ "width": "200px" }} size="small" options={drawOptions()} />
                            </div>
                        </Space>
                    </>}
                    button={
                        <Overlay width={500} component={<DisplayForm isnew={true} />}><Button size="small" type="primary"><small>Create form</small></Button></Overlay>
                    }
                />

                <Layout.Content style={{ "padding": "0px" }} >
                    <div style={{ "padding": "20px" }}>
                        <Table
                            bordered
                            rowKey={"id"}
                            columns={columns}
                            dataSource={results.rows}
                            loading={loading}
                            pagination={false}
                        />
                    </div>
                </Layout.Content>
            </Layout >
        </>
    )


}