import React, { useState, useEffect } from "react";
import { Menu, Avatar, Space, Input, Select, Tag, Button, Upload, Row, Col, Divider, Tooltip, Badge } from 'antd';
import {
    SettingOutlined,
    EditOutlined,
    BarChartOutlined,
    DollarOutlined,
    ToolOutlined,
    ShoppingCartOutlined,
    CustomerServiceOutlined,
    MailOutlined,
    SelectOutlined,
} from '@ant-design/icons';

import conditional from "../tools/conditional";
import logosmall from "../media/logo.png";
import logodark from "../media/logo_dark.png"

import AccountCard from "./AccountCard";
import { navigation } from "../data";
import { useHistory } from "react-router-dom";
import AccountBox from "./AccountBox";
import { getISBNCount, getFilterCount, objectToPath, pathToObject, handleTermSlash } from "../utils/Utils";
import Notifications from "./Notifications";
import { useSession } from "../utils/Session";
import { apiCall } from "../utils/Api";
import ResizeText from "./ResizeText";
import Cookies from "js-cookie";

export default function Nav2(props) {


    const history = useHistory();
    const [collapsed, setCollapsed] = useState(true);
    const [opened, setOpened] = useState("");
    const [selected, setSelected] = useState("");
    const [session, setSession] = useSession();
    const [processing, setProcessing] = useState(false);



    // const getSearchType = () => {
    //     if (history.location.pathname.includes("/k/")) {

    //         let _arr = history.location.pathname.split("/k/");
    //         if (_arr.length > 1) {
    //             let _spt = _arr[1].split("/");
    //             if (_spt.length > 0) {
    //                 return _spt[0];
    //             } else {
    //                 return _arr[1];
    //             }
    //         }

    //     } else {
    //         return "keyword";
    //     }
    // }





    // document.addEventListener('keypress', e => {
    //   //usually scanners throw an 'Enter' key at the end of read
    //    if (e.keyCode === 13) {
    //           if(code.length > 10) {
    //             console.log(code);
    //             /// code ready to use                
    //             code = "";
    //          }
    //     } else {
    //         code += e.key; //while this is not an 'enter' it stores the every key            
    //     }

    //     //run a timeout of 200ms at the first read and clear everything
    //     if(!reading) {
    //         reading = true;
    //         setTimeout(() => {
    //             code = "";
    //             reading = false;
    //         }, 200);  //200 works fine for me but you can adjust it
    //     }
    // });


    const isTextArea = () => {

        if(document.activeElement instanceof HTMLTextAreaElement || document.activeElement instanceof HTMLInputElement){
            return true; 
        } else {
            return false; 
        }

    }



    // BARCODE SCANNER
    let inputString = "";
    let reading = false;
    useEffect(() => {
        function handleScan(event) {

           
            


            if (event.key === "~") {

                if(isTextArea()){
                   event.preventDefault();
                   event.stopPropagation();
                    return; 
                }


                inputString = "";
                reading = true;
                setTimeout(() => {
                    reading = false;
                }, 1500)
            } else if (event.key === "Enter") {
                if (reading) {
                    setKeyword(inputString);
                    linkToBrowse(inputString, true);
                    reading = false;
                }
            } else {
                if (reading) {
                    inputString += (event.key === "Shift") ? "" : event.key;
                }
            }
        }

        document.addEventListener('keydown', handleScan, true);
        return () => {
            document.removeEventListener('keydown', handleScan, true);
        };
    }, []);



    const getTerm = () => {
        if (history.location.pathname.includes("/t/")) {

            let _arr = history.location.pathname.split("/t/");
            if (_arr.length > 1) {
                let _spt = _arr[1].split("/");
                if (_spt.length > 0) {
                    return handleTermSlash(_spt[0], false);
                } else {
                    return handleTermSlash(_arr[1], false);
                }
            }

        } else {
            return "";
        }
    }

    const [keyword, setKeyword] = useState(getTerm());

    const [searchType, setSearchType] = useState("keyword");


    const selectBefore = (

        <Select onChange={(_v) => setSearchType(_v)} style={{ "border": "none", "width": "90px" }} defaultValue={searchType}>
            <Select.Option key="keyword" value="keyword"><small>Keyword</small></Select.Option>
            <Select.Option key="title" value="title"><small>Title</small></Select.Option>
            <Select.Option key="author" value="author"><small>Author</small></Select.Option>
            <Select.Option key="publisher" value="publisher"><small>Publisher</small></Select.Option>
            <Select.Option key="url" value="url"><small>URL</small></Select.Option>
            <Select.Option key="file" value="file"><small>File</small></Select.Option>
        </Select>

    );


    const resetKeyword = () => {
        setKeyword(getTerm());
    }

    useEffect(resetKeyword, [history.location.pathname]);



    const hadBetaAccess = (_item, _init) => {

        return _init; 
        // first check beta
        if (_item.hasOwnProperty("beta")) {
            if (!window.sitesettings.beta_access[_item.beta]) {
                return false;
            }
        } else {
            return _init;
        }
    }

    const hasPermission = (_item) => {
        // ensure permission exists on nav item and is in session
        if (_item.hasOwnProperty("permission")) {
            let has = true;
            _item.permission.forEach(element => {
                if (session.permissions.hasOwnProperty(element) && !session.permissions[element]) {
                    has = false;
                }
            });
            return has;
        }

        return true;
    }


    const checkCondition = (_navItem) => { 
        if(_navItem.hasOwnProperty("condition")){
            return window.sitesettings[_navItem.condition];
        } 
        return true; 
    }

    const drawMenu = () => {
        return navigation.map((item) => {


            

            return (
                <Menu.SubMenu style={{ "padding": "0px 9px" }} onTitleClick={() => setOpened(item.slug)} key={item.slug} icon={getIcon(item.slug)} title={item.name}>
                    {item.subnav.filter(item => checkCondition(item)).map((_item) => {

                        let access = _item.active;
                        if (access) {
                            access = hadBetaAccess(_item, access)
                            if (access) {
                                access = hasPermission(_item);
                            }
                        }
                        // check hide flag
                        if (!access && _item.hasOwnProperty("hide")) {
                            return (<></>)
                        }

                        return (<Menu.Item disabled={(!access)} onMouseDown={(e) => {
                            if (e.button === 1 && access) {
                                linkToPage(_item, true);
                            }
                        }} onClick={() => linkToPage(_item)} key={_item.slug}>

                            <Tooltip title={(!hasPermission(_item)) ? <small>Your login does not have permission to access this area. Please login with a different account that has access, or go to the Settings → Permissions menu to modify which accounts have access to specific areas.</small> : ""}>{_item.name} </Tooltip>

                            {(_item.hasOwnProperty("link") && <small>&nbsp;<SelectOutlined style={{ "transform": "scaleX(-1)", "fontSize": "12px" }} /></small>)}</Menu.Item>)
                    })}
                </Menu.SubMenu>
            )
        })
    }

    const getIcon = (_slug) => {

        return (<></>);

        switch (_slug) {
            case "settings":
                return (<SettingOutlined />);
            case "content":
                return (<EditOutlined />);
            case "reports":
                return (<BarChartOutlined />);
            case "purchasing":
                return (<DollarOutlined />);
            case "tools":
                return (<ToolOutlined />);
            case "support":
                return (<CustomerServiceOutlined />);
        }
    }

    const linkToPage = (_item, _newtab = false) => {


        if (_item.hasOwnProperty("link")) {
            window.open(_item.slug, "_blank");
            return;
        }

        // Route defaults for browse, mylist, categories
        let _retpath = _item.slug;
        let _pushstate = false;


        switch (_item.slug) {
            case "status":
                _pushstate = true;
                apiCall("toolbox/pingUsed", { id: session.filter_preset_defaults.status.id }, () => { });
                _retpath = "purchasing/browse" + objectToPath(session.filter_preset_defaults.status.selections);
                break;

            case "listcat":
                _pushstate = true;
                apiCall("toolbox/pingUsed", { id: session.filter_preset_defaults.listcat.id }, () => { });
                _retpath = "purchasing/catalogues" + objectToPath(session.filter_preset_defaults.listcat.selections);
                break;

            case "mylists":
                _pushstate = true;
                apiCall("toolbox/pingUsed", { id: session.filter_preset_defaults.mylists.id }, () => { });
                _retpath = "purchasing/my-lists" + objectToPath(session.filter_preset_defaults.mylists.selections);
                break;

        }

        setSelected(_retpath);

        if (_newtab) {
            window.open("/" + _retpath, "_blank");
        }

        if (_pushstate) {
            history.push({ pathname: "/" + _retpath, state: true });
        } else {
            history.push({ pathname: "/" + _retpath });
        }

    }

    const generateFile = (_val) => {
        var filename = 'pasted.txt';
        let _file = new File([new Blob([_val])], filename)
        let f = {}
        f.file = _file;
        return f;
    }

    const getCacheKeyForFile = (_file) => {
        setProcessing(true);
        apiCall("browse/getCacheKeyForFile", { file: _file.file }, (_status, _result) => {
            if (_status) {
                history.push({ pathname: "/purchasing/browse/filter/v/sequence/x/" + _result.id, state: Math.random().toString() })
            }
            setProcessing(false);
        });
    }

    const convertURL = (_url) => {
        apiCall("url_converter/convertCustomerUrlToBiz", { url: _url }, (_status, _result) => {
            if (_status) {
                // history.push(_result.url);
                history.push({ pathname: _result.url, state: Math.random().toString() })
            } else {
                linkToBrowse(_url, false, true);
            }
        })
    }

    const linkToBrowse = (_val, _clear = false, _skipURL = false) => {


        


        if (!_skipURL && (_val.substr(0, 5) === "http:" || _val.substr(0, 6) === "https:")) {
            convertURL(_val);
            return;
        }

        _val = handleTermSlash(_val, true);

        // if search contains 1 or more ISBNS 
        if (getISBNCount(_val) > 1) {
            getCacheKeyForFile(generateFile(_val));
            return;
        }




        let _pathname = "";
        if (_val) {

            if (history.location.pathname.includes("browse/filter") && !_clear) {
                let obj = pathToObject(history.location.pathname);
              
                obj.v = ""
                obj.x = "";
                obj.t = _val;

                if (!obj.hasOwnProperty("k")) {
                    if (searchType !== "keyword") {
                        obj.k = searchType;
                        _pathname = "/purchasing/browse" + objectToPath(obj);
                    }
                } else {
                    obj.k = searchType;
                    _pathname = "/purchasing/browse" + objectToPath(obj);
                }

            } else {

                let sort = (Cookies.get("default_search_sort") !== undefined) ? Cookies.get("default_search_sort") : "in_store";
                if(sort){
                    sort = "/v/" + sort
                }
           
                //_pathname = "/purchasing/browse/filter/t/" + _val;
                // Default sort and filter for cleared header searches
                _pathname = "/purchasing/browse/filter/a/active"+sort+"/t/" + _val;
                if (searchType !== "keyword") {
                    _pathname += "/k/" + searchType;
                }
            }

        } else {
            _pathname = "/purchasing/browse";
            history.push("/purchasing/browse");
        }



        history.push({ pathname: _pathname, state: _val + Math.random().toString() })

    }

    const drawClearFilters = () => {

        // Removed 
        return false;

        if (getFilterCount(history.location.pathname) && keyword) {

            return <div>&nbsp;&nbsp;<Tag onClick={() => linkToBrowse(keyword, true)} style={{ "cursor": "pointer", "marginRight": "0px" }} color="orange">Clear filters</Tag></div>
            // return(<div style={{"borderLeft" : "1px solid #eee"}}>&nbsp;&nbsp;<Checkbox checked={(clearFilters)} onChange={() => setClearFilters(!clearFilters)} ><small style={{"color" : "#aaa", "fontWeight" : "600"}}>Clear filters?</small></Checkbox></div>)
        } else {
            return "";
        }

    }

    const getCurrentKey = () => {



        if (history.location.pathname.includes("purchasing/browse")) {
            return "status";
        }

        if (history.location.pathname.includes("purchasing/catalogues")) {
            return "listcat";
        }

        if (history.location.pathname.includes("purchasing/my-lists")) {
            return "mylists"
        }



        let m = history.location.pathname.split("/");
        let s = "";
        if (m.length > 1) {

            s = m[m.length - 2] + "/" + m[m.length - 1];

        }
        return s;
    }

    return (
        <div >

            {/* <div style={{ "float": "right" }}>
                <Space size={0}>
                    <div onClick={() => history.push("/purchasing/cart")}><Avatar size="small" style={{ "backgroundColor": "rgb(255,255,255,0.2)", "cursor": "pointer" }} icon={<ShoppingCartOutlined />} /></div>
                    <div style={{ "width": "13px" }}>&nbsp;</div>
                    <Notifications />
                    <AccountBox />
                </Space>
            </div> */}

            <Row align="middle" >
                <Col style={{ "maxWidth": "230px" }}><ResizeText text={session.store_name} /></Col>
                <Col flex={"8px"}><div style={{ "color": "#888", "textAlign": "right" }}>&nbsp;</div></Col>
                <Col style={{ "maxWidth": "390px" }} flex={"390px"}><Menu style={{ "width": "390px" }} selectedKeys={[getCurrentKey()]} mode="horizontal" theme="dark" >{drawMenu()}</Menu></Col>
                <Col flex={"auto"} >


                    {/* {(searchType === "file" && <>
                        <Input.Search className="clean-input" style={{ "minWidth": "100px", "maxWidth": "450px" }} enterButton disabled addonAfter={<div style={{ "display": "inline-block", "position": "relative", "right": "193px", "width": "0px" }}><Upload showUploadList={false} customRequest={(_file) => getCacheKeyForFile(_file)}><Button loading={processing} disabled={(processing)} type="primary" size="small"><small>Choose a file <small> &nbsp;(max 50MB)</small></small></Button></Upload></div>} value={keyword} addonBefore={selectBefore} />
                    </>)} */}

                    {(searchType === "file" && <>

                        <div className="group-addon drag-selector" style={{"display" : "flex"}}>
                            {selectBefore}
                            <div style={{"width" : "100px", "maxHeight" : "30px"}}>
                                <Upload.Dragger showUploadList={false} customRequest={(_file) => getCacheKeyForFile(_file)}>
                                    Drag file or click to upload...
                                </Upload.Dragger>
                            </div>
                        </div>
                        

                    </>)}

                    {(searchType !== "file" && <>

                        {/* value={keyword} removed by Kellynda 1157 */}
                        <Input.Search value={keyword} addonBefore={selectBefore} suffix={drawClearFilters()} onChange={(e) => setKeyword(e.target.value)} onSearch={(e) => linkToBrowse(e, true)} className="clean-input" allowClear enterButton placeholder="Keywords, author, title or series..." style={{ "minWidth": "100px", "maxWidth": "450px" }} />
                    </>)}

                </Col>
                <Col flex={"8px"}><div style={{ "color": "#888", "textAlign": "right" }}>&nbsp;</div></Col>
                <Col flex={"210px"} style={{ "maxWidth": "230px" }}>
                    <div style={{ "float": "right" }}>
                        <Space size={0}>
                       
                            <Badge style={{"boxShadow" : "none"}} dot={(session.cart.length>0)}><div onClick={() => history.push("/purchasing/cart")}><Avatar size="small" style={{ "backgroundColor": "rgb(255,255,255,0.2)", "cursor": "pointer" }} icon={<ShoppingCartOutlined />} /></div></Badge>
                            <div style={{ "width": "7px" }}>&nbsp;</div>
                            <Notifications />
                            <AccountBox />
                        </Space>
                    </div>
                </Col>

            </Row>

            <br clear="all" />
        </div>
    )
}