import React, { useState } from "react";
import { Card, Input, Dropdown, AutoComplete, Button, Modal, message } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { getMemory, saveMemory } from "../utils/Utils";
import { ReloadOutlined } from '@ant-design/icons';
export default function CategorySelector(props) {

    const {suggested_bm_data = { class_1: "", class_2: "" }, category = "", subCategory = "", setCategory = () => { }, setSubCategory = () => { }, callback = () => { } } = props;

    // set default from the cart
    const [initCat, setInitCat] = useState(category ? category : "")
    const [initSubCat, setInitSubCat] = useState((subCategory) ? subCategory : "")
    const [selected, setSelected] = useState("");
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState((subCategory) ? category + " - " + subCategory : category);
    const [focus, setFocus] = useState(false);
    const [visible, setVisible] = useState(false);
    let _store = window.sitesettings.stores.find(item => item.is_primary);
    const [list, setList] = useState(window.sitesettings.categories[_store.san] || [])

    const parseMemory = (_memory) => {

        if (!Array.isArray(_memory) || _memory.length < 1) {
            return [];
        }

        return _memory.map(item => {

            let _arr = item.split("~");
            let _obj = {}

            if (_arr.length > 0) {
                _obj.value = _arr[0];
                _obj.label = _arr[0];
                if (_arr.length > 1 && _arr[1]) {
                    _obj.value = _arr[0] + " - " + _arr[1];
                    _obj.label = _arr[0] + " - " + _arr[1];
                }
                _obj.key = Math.random(100000);
                return _obj;
            } else {
                return false;
            }
        })
    }

    const _memory = parseMemory(getMemory("category"));

    let suggested_class = {}
    if (initCat) {
        if (initSubCat) {
            suggested_class.label = initCat + " - " + initSubCat;
        } else {
            suggested_class.label = initCat;
        }
        suggested_class.key = Math.random(100000);
        suggested_class.value = suggested_class.label
    }

    const searchFilter = (_search = true) => {


        if(!_search){
            return list; 
        }

        let _ret = [];
        if (key) {
            _ret = list.filter(item => {
                if (item.label.toUpperCase().includes(key.toUpperCase())) {
                    return item;
                }
            });
        } else {
            _ret = list;
        }

        if(key){
            return _ret.sort((a,b) =>{
               return (a.label.toUpperCase().indexOf(key.toUpperCase()) < b.label.toUpperCase().indexOf(key.toUpperCase())) ? -1 : 1
            })
        }
        return (_ret) ? _ret : [];
    }

    const setClass = (_item) => {
        setKey(_item.label);
        setSelected(_item.label);
        setVisible(false);
        setCategory(_item.class_1);
        if (_item.class_2) {
            setSubCategory(_item.class_2);
            saveMemory("category", _item.class_1 + "~" + _item.class_2);
            callback("", _item.class_1, _item.class_2, true);
        } else {
            setSubCategory("");
            saveMemory("category", _item.class_1 + "~" + "");
            callback("", _item.class_1, "", true);
        }
        setOpen(false)
    }


    const setRepeatByKey = (_key) => {

        let _mem = getMemory("category_key");

        if(_mem.length > 0){
            _key = _mem[0]
        } else {
            message.error("No previous category found.")
            return; 
        }
        
        let _catarr_obj = getOptions(false).find(item => item.label === "Categories");

        if(!_catarr_obj){
            return;
        }

        let _find = _catarr_obj.options.find(item => item.value === _key)

        if(!_find){
            Modal.error({title : "Not found."});
        }

        let obj = {}
        let _classes = _key.split(" - ");
        if (_classes.length === 2) {
            obj.label = _classes[0] + " - " + _classes[1];
            obj.class_1 = _classes[0];
            obj.class_2 = _classes[1];
            setClass(obj);
        } else {
            obj.label = _classes[0];
            obj.class_1 = _classes[0];
            obj.class_2 = "";
            setClass(obj);
        }

        setKey(_key)

    }

    const drawRepeat = () => {
       
        
        return <Button onClick={() => setRepeatByKey()} size="small" type="text"><small><ReloadOutlined /></small></Button>
    }


    const drawResults = () => {
        return searchFilter().map(item => {
            return (<div style={{ "cursor": "pointer" }} onClick={(e) => setClass(item)} className="c"><small>{item.label}</small></div>)
        })
    }

    const inside = () => {
        return (<>
            <div onMouseLeave={() => setVisible(false)} style={{ "width": "170px", "marginTop": "-5px" }} >
                <div className="shim" /><div className="shim" />
                <Card bodyStyle={{ "maxHeight": "300px", "overflow": "auto", "padding": "10px", "boxShadow": "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)" }}>
                    {drawResults()}
                </Card>
            </div>
        </>)
    }

    const getPlaceHolder = () => {

        let ret = ""
        if (suggested_bm_data.class_2) {
            return suggested_bm_data.class_1 + " - " + suggested_bm_data.class_2;
        } else {
            return suggested_bm_data.class_1;
        }

    }

    const getOptions = (_search = true) => {

        let _arr = [];
        if (!key) {
            if (_memory.length > 0) {
                _arr.push({
                    label: "Recent",
                    options: (key) ? [] : _memory.slice(0, 3),
                })
            }
        }

        if (searchFilter(_search).length > 0) {
            _arr.push(
                {
                    label: "Categories",
                    options: searchFilter(_search).map(item => {
                        let _v = item.class_1;
                        if (item.class_2) {
                            _v += " - " + item.class_2;
                        }
                        return ({ value: _v, label: <>{item.label} &nbsp;</> })
                    }),
                }
            )
        }
        return _arr;
    }

    return (
        <>  
         <div className="float-flex">
            <AutoComplete
                value={key}
                allowClear
                onFocus={(e) => {
                    setKey((category) ? category : suggested_bm_data.class_1)
                }}
                onBlur={() => {
                    setKey((subCategory) ? category + " - " + subCategory : category)
                }}
                onChange={(e) => {
                    if(e){
                        saveMemory("category_key", e)
                    }
                   
                    setKey(e)
                }}
                onSelect={(e) => {
                    let obj = {}
                    let _classes = e.split(" - ");
                    if (_classes.length === 2) {
                        obj.label = _classes[0] + " - " + _classes[1];
                        obj.class_1 = _classes[0];
                        obj.class_2 = _classes[1];
                        setClass(obj);
                    } else {
                        obj.label = _classes[0];
                        obj.class_1 = _classes[0];
                        obj.class_2 = "";
                        setClass(obj);
                    }
                }}
                className="redPlaceholder"
                placeholder={(suggested_bm_data.class_1) ? "* " + getPlaceHolder() : "Select Class.. "}
                style={{ "width": "100%", "fontSize": "11px", fontWeight : "bold", "height": "22px", "textAlign": "left", "marginTop": "0px", "border": "none" }}
                dropdownClassName="tinyDropdown"
                size="small"
                options={getOptions()}
            />
            {drawRepeat()}
            </div>
        </>
    )

}