import { Button, Divider, Input, Popover } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { PlusSquareFilled } from '@ant-design/icons';
import { getMemory, saveMemory } from "../utils/Utils";

export default function GroupPicker(props) {

    const { show_plus_circle = false } = props;
    const inputRef = useRef(null);
    const {
        disabled = false,
        value = 0,
        onChange = () => { },
    } = props;

    const [val, setVal] = useState((value) ? value : "");
    const [hover, setHover] = useState(false);

    const memory = getMemory("grouppicker");
    const defaults = ["O", "R", "B", "F", "S", "W"];

    let mergedSet = new Set([...memory, ...defaults]);
    let presets = Array.from(mergedSet).slice(0, 6);
    // presets = presets.sort();

    useEffect(() => {
        setVal(value)
    }, [value]);

    const change = (_v) => {
        handleHover(false)
        addMemory(_v);
        onChange(_v);
    }

    const addMemory = (_val) => {
        if (_val.length === 1) {
            saveMemory("grouppicker", _val)
        }
    }

    const handleVal = (_e, _update = false, _blur = false) => {

        var regex = /^[0-9a-zA-Z]+$/;
        let valid = regex.test(_e.target.value);
        let v = _e.target.value.toUpperCase();
        if (!valid) { v = ""; }

        if (_blur && (v == value)) {
            // if value matches on blur, don't update (already up to date)
            _update = false;
        }
        if (_update) {
            // onChange will set the local val pointless to set it twice
            addMemory(v);
            handleHover(false);
            change(v);
        } else {
            setVal(v);
        }
    };


    const handleHover = (_v) => {
        if (_v !== hover) {
            setHover(_v);
        }
    }

    const NumBtn = (props) => {
        const { num = 0 } = props
        return (<Button onClick={() => change(num)} size="small">{num}</Button>)
    }

    const MemBtn = (props) => {
        const { index = 0 } = props;
        let _val = presets[index];
        return (<Button onClick={() => change(_val)} size="small"><small>{_val}</small></Button>)
    }

    const handleMouseLeave = () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const stores = window.sitesettings.stores.map((item, index) => { return item.store_number.toString() })
    const groups = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];


    const handleKeyPress = (_e) => {

        if (_e.key === 'Enter') {
            handleVal(_e, true);
        }
    };


    const segmentArray = (_arr, _size) => {

        const segments = Array.from({ length: Math.ceil(_arr.length / _size) }, (v, i) =>
            _arr.slice(i * _size, i * _size + _size)
        );

        return (
            <div>
                {segments.map((_child, _index) => (
                    <div key={_index} className="group-select-row">
                        {_child.map((_chitem, _chindex) => (
                            <NumBtn key={_chindex} num={_chitem} />
                        ))}
                    </div>
                ))}
            </div>
        );
    }


    const content = (
        <>
            <div onMouseOver={() => handleMouseLeave()} style={{ "padding": "3px" }}>

                {((stores.length > 1) && segmentArray(stores, 6))}
                <Divider plain style={{ "margin": "3px -2px" }} />

                {segmentArray(groups, 6)}
                <Divider plain style={{ "margin": "3px -2px" }} />

                <div className="group-select">
                    <div className="group-select-row">
                        <MemBtn index={0} />
                        <MemBtn index={1} />
                        <MemBtn index={2} />
                        <MemBtn index={3} />
                        <MemBtn index={4} />
                        <MemBtn index={5} />
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <>
            <Popover placement="bottom" onVisibleChange={handleHover} visible={(hover && !disabled)} overlayClassName="group-select" className="group-select" openClassName="num-select" content={content}>


                {(show_plus_circle && <div style={{"textAlign" : "center", "cursor" : "pointer"}}>&nbsp;&nbsp;&nbsp;&nbsp;<PlusSquareFilled style={{ "pointerEvents": "none", "marginLeft": "-18px", "color": "#007EF5", "fontSize" : "15px", "border" : "none" }} /></div>)}
                {(!show_plus_circle &&
                    <Input
                        ref={inputRef}
                        disabled={disabled}
                        onKeyDown={(e) => handleKeyPress(e)}
                        mouseEnterDelay={0}
                        onBlur={(e) => handleVal(e, true, true)}
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => handleVal(e)}
                        value={(val != false) ? val : ""}
                        
                        style={{
                            "width": "30px",
                            "fontSize": "11px",
                            "height": "23px",
                            "marginTop": "3px",
                            "padding": "0px 5px",
                            
                        }}
                        className={(disabled) ? "tinyInput groupBG" : "tinyInput groupBG"} />
                )}
            </Popover>
        </>
    )
}