import React from "react";
import { Form, Switch, Typography, Space } from "antd";
import conditional from "../../utils/conditional";
export default function MultistoreItem(props) {


    const {
        stores = [],
        ws = [],
        has_switch = true,
        disabled = false,
        disabledMsg = "",
        heading = "",
        description = "",
        value = false,
        name = "",
        warning = false,
        notice = "",
        onChange = null,
    } = props;


    const setOnChange = (e, _name = "") => {

        if (onChange) {
            props.onChange(e, _name);
        }

    }

    return (
        <>
            <Space align="start" size={15}>
                <div>
                    <conditional.true value={(stores.length > 1)}>
                        <Typography.Title level={5}>&nbsp;</Typography.Title>
                    </conditional.true>
                    {stores.map(item => {
                        let _name = "ms" + item.store_number + ":" + name;
                        return (
                            <div style={{ "height": "24px", "overflow": "hidden" }}>
                                <Form.Item valuePropName='checked' initialValue={ws[_name]} name={_name} noStyle>
                                    {(has_switch && <Switch disabled={disabled} onChange={(e) => setOnChange(e, _name)} size="small" />)}
                                </Form.Item>
                            </div>
                        )
                    })}
                </div>
                {(!has_switch && <div style={{ "width": "28px" }}>&nbsp;</div>)}
                <div>
                    <Typography.Title level={5}>{heading}</Typography.Title>
                    <conditional.true value={(stores.length > 1)}>
                        {stores.map(item => {
                            return (<div style={{ "height": "24px", "overflow": "hidden", "lineHeight": "27px" }}>{item.altname}</div>)
                        })}
                        <div className="shim" /><div className="shim" />
                    </conditional.true>
                    
                    <conditional.true value={(disabled && disabledMsg)}>
                        <Typography.Paragraph type="danger">{disabledMsg}</Typography.Paragraph>
                    </conditional.true>
                    <Typography.Paragraph type="secondary">
                        <conditional.true value={(notice)}>
                            <Typography.Paragraph type={(warning) ? "danger" : "secondary"} ><em><strong>{notice}</strong></em></Typography.Paragraph>
                        </conditional.true>
                        {description}</Typography.Paragraph>
                </div>
            </Space>
        </>
    )

}