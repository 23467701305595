
import React, { useState, useEffect } from "react";
import { Button, Divider, Typography, Table, Modal, message, Checkbox, Input, Space } from "antd";
import { apiCall } from "../../utils/Api";
import { bmDate } from "../../utils/Utils";
import Overlay from "../Overlay";
import ListEdit from "./ListEdit";
import ListQuickPick from "./ListQuickPick";
import { useSession } from "../../utils/Session";
import { ShareAltOutlined } from '@ant-design/icons'
import Share from "../title/Share";
export default function ListCount(props) {

    const { isbn = "", my_lists = [], title="", updateMyListsInParent = () => {}} = props;
    const [res, setRes] = useState({rows : []})
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [search, setSearch] = useState("");
    const [session, setSession] = useSession();


    const linkOut = (_id) => {
        window.open("/purchasing/browse/filter/x/" + _id + "/v/sequence", "_blank");
    }

    const updateMyLists = (_record) => {
        let _my_lists = [...my_lists];
        if(!_my_lists.includes(_record)){
            _my_lists.push(_record);
        }else{
            _my_lists.splice(_my_lists.indexOf(_record), 1);
        }
        updateMyListsInParent(_my_lists);
    }

    const updateRow = (_record) => {
        let _res = {...res};
        let _find = _res.rows.findIndex(item => item.list_id === _record.list_id);
        if(_find > -1){
            _res.rows[_find].in_list = _record.in_list; 
        }
        setRes(_res);
    }

    const toggle = (_list_id, _isbn, _checked = true) => {
        if(_checked){
            apiCall("titlelist/addItemToList", {isbn : _isbn, list_id : _list_id}, (_status, _result) => {
                if(_status){
                    updateMyLists(_result.actual_id);
                    updateRow(_result);
                } else {
                    message.error(_result.error);
                }
            })
        } else {
            apiCall("titlelist/removeItemFromList", {isbn : _isbn, list_id : _list_id}, (_status, _result) => {
                if(_status){
                    updateMyLists(_result.actual_id);
                    updateRow(_result);
                } else {
                    message.error(_result.error);
                }
            })
        }
    }

    const openModal = () => {
        setVisible(true);
        fetchMyLists();
    }

    const fetchMyLists = (_search) => {
        setLoading(true);
        let obj = {
            isbn : isbn,
            g: "-Pick List" // Exclude picklists....
        }
        if(_search){
            obj.t = _search; 
        }
        apiCall("titlelist/getMyLists", obj, (_status, _result) => {
            if (_status) {
                setRes(_result);
            } else {
                message.error(_result.error);
            }
            setLoading(false);
        })
    }

    const close = () => {
        setVisible(false);
        fetchMyLists();
    }

    const searchRows = () =>{
        return res.rows.filter(item => item.name.includes(search))
    }

    const updatedCount = () => {
        return my_lists.length;
    }

    const hasQP = (_id) =>{
        return (session.quick_pick_lists.some(item => item.actual_id === _id)) ? 1 : 0;       
    }

    const drawMyLists = () => {
        return (
            <Modal destroyOnClose={true} onCancel={() => close(false)} footer={null} visible={visible}>
                <div style={{ "padding": "0px" }} >
                    <Typography.Title level={4}>
                        <div>{title}</div>
                    </Typography.Title>
                    <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                        <div>My lists ({(updatedCount())})</div>
                        <Divider dashed style={{ "marginTop": "10px", "marginBottom": "5px" }} />
                    </Typography.Title>
                    <Space style={{"float" : "right"}}>
                        <Button type="primary" size="small"><small><Overlay width={700} component={<ListEdit create={true} callback={fetchMyLists} />}>Create</Overlay></small></Button>
                    </Space>
                    <Input.Search onBlur={(e) => {
                        if(e.target.value === ""){
                            fetchMyLists("");
                        }
                    }} onSearch={(_search) => fetchMyLists(_search)} onChange={(e) => setSearch(e.target.value)} placeholder="Search lists..." style={{"width" : "300px"}} size="small" enterButton />
                    <div className="shim" />
                    <Table
                        className="bdr"
                        loading={loading}
                        rowKey="list_id"
                        size="small"
                        pagination={false}
                        columns={columns}
                        scroll={{ y: 258 }}
                        dataSource={res.rows.sort((a, b) => hasQP(b.actual_id) - hasQP(a.actual_id))}
                    />
                    <br />
                    <div style={{ "float": "right" }}>
                        <Button onClick={() => close(false)}>Close</Button>
                    </div>
                    <div><em>Showing 25 most recently updated lists. Use Search to find more.</em></div>
                    <br clear="all" />
                </div>
            </Modal>
        )
    }


    const columns = [
        { title: <small>In List</small>, width: 70, dataIndex: 'vendor', key: 'vendor', render : (e, f) => {
            return (<Checkbox onClick={(e) => toggle(f.list_id, isbn, e.target.checked)} checked={(f.in_list) ? "checked" : ""} disabled={f.is_dynamic}/>)
        }},
        {
            title: <small>Name</small>, dataIndex: 'name', key: 'name', render: (e, f) => {
                return (e) ? <a onClick={() => linkOut(f.list_id)} >{e}</a> : "";
            }
        },
        { title: <small>Titles</small>, width: 60, dataIndex: 'isbns_count', key: 'isbns_count' },
        {
            title: <small>Updated</small>, width: 70, dataIndex: 'date_updated', key: 'date_updated', render: (e) => {
                return (e) ? <nobr>{bmDate(e * 1000)}</nobr> : ""
            }
        },
        { title: <small>QP</small>, width: 40, dataIndex: 'quickpick', key: 'quickpick', render: (e, f) => {
            return <ListQuickPick list={f} list_id={f.list_id}/>
        }},

        { title: <small></small>, width: 50, dataIndex: 'share', key: 'share', render: (e, f) => {
            return <Share customer_path={"lists/" + f.list_id} store_path={"biz/purchasing/browse/filter/x/" + f.list_id + "/v/sequence"} ><Button size="small"><ShareAltOutlined /></Button></Share>
        }},
    ]




    return (
        <>
            {drawMyLists()}
            <Button onClick={() => openModal()} size="small" className="mini-btn" ><small>My lists ({(updatedCount())}) </small></Button>
        </>
    )
}