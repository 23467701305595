import { CheckSquareFilled, CloseOutlined, CloseSquareFilled } from '@ant-design/icons';
import { Affix, Button, Col, Divider, Row, Space, Tag, Tooltip } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import conditional from "../utils/conditional";
import { bestsellerColor, handleTermSlash, objectToPath, pathToObject, successColor, ucfirst } from "../utils/Utils";
import DropMenu from "./DropMenu";
import FilterAutocomplete from "./FilterAutocomplete";
import FilterPresetLabel from "./FilterPresetLabel";
import FilterPresets from "./FilterPresets";
import MagicFilters from "./MagicFilters";
import Metafilters from "./Metafilters";

export default function BrowseFilters5(props) {

    const { 
        context = {name : "", negated : false}, 
        setToolboxes = () => {}, 
        display_mode = "", 
        block = false, 
        listLabel = { key: "" }, 
        toolboxes = {}, 
        loading = true, 
        filters = {}, 
        setFilters = () => { }, 
        header = "status", 
        getBrowse = () => { }, 
        searchFilters = {}, 
        setSearchFilters = () => { }, 
        display = "", 
        path = "/purchasing/browse" 
    } = props;
    const history = useHistory();
    const [metaSet, setMetaSet] = useState([]);
    const [vistoggle, setVistoggle] = useState("");
    const changed = decodeURI(objectToPath(searchFilters).replace("/filter", "")) !== history.location.pathname.replace(path, "").replace("/filter", "");

    const hasBanner = (window.sitesettings.banner_message.text || !window.sitesettings.is_v3) ? true: false;

    const tips = window.sitesettings.tool_tips;
    const go = () => {
        if (changed) {
            props.setCurrent(1);
            if (searchFilters.hasOwnProperty("o")) {
                delete searchFilters.o;
            }
            getBrowse(false, searchFilters);

            let _loc = pathToObject(history.location.pathname);
            if(_loc.hasOwnProperty("p")){
                history.push({ pathname: path + objectToPath({...searchFilters, "p" : _loc.p}, true) });
            } else {
                history.push({ pathname: path + objectToPath(searchFilters, true) });
            }
           
            setVistoggle("close");
        }
    }

    const formatCount = (_count = "0") => {
        if (!_count) {
            return;
        }
        if (_count.length > 3) {
            if (_count.length > 6) {
                return _count.substring(0, _count.length - 5) / 10 + "m";
            }
            return _count.substring(0, _count.length - 3) + "k";
        }
        return _count
    }

    const resetFilter = (_key) => {
        setVistoggle("close");
        setSearchFilters({ ...searchFilters, [_key]: [] });
        getBrowse(false, { ...searchFilters, [_key]: [] })

    }

    const getNameByKey = (_key, _letter) => {
        if (_key.substr(0, 1) === "-") {
            _key = _key.substr(1, _key.length);
        }
        let _fltr = filters.find(item => item.routing_char === _letter);
        if (_fltr && _fltr.hasOwnProperty("rows")) {
            let _cat = _fltr.rows.find(item => item.key === _key);
            if (_cat) {
                return _cat.name;
            } else {
                let ret = _key;
                _fltr.rows.forEach(itm => {
                    if (itm.hasOwnProperty("subfilter")) {
                        let _sub = itm.subfilter.find(item => item.key === _key);
                        if (_sub) {
                            ret = _sub.name;
                        }
                    }
                });
                return ret;
            }
        }
        return "";
    }

    const drawSelectedTags = (_selected = [], _letter, _exclude = false) => {
        // console.log(searchFilters);
        if (searchFilters.hasOwnProperty(_letter)) {
            _selected = searchFilters[_letter];
        } else {
            _selected = [];
        }
        return (
            <Row align="top">
                <Col flex={"100%"} >
                    <div style={{ "marginTop": "-5px", "maxHeight": "10vh", "overflow": "auto" }}>
                        {_selected.filter(i => i !== "AND").map((item, index) => {
                            return (
                                <Tag key={index} onClick={(e) => { e.preventDefault(); e.stopPropagation(); toggleCategory(_letter, (item.substr(0, 1) === "-") ? item.substr(1, item.length) : item, true, (item.substr(0, 1) === "-") ? "exclude" : "include"); }} color={(item.substr(0, 1) === "-") ? "red" : "green"} onClose={(e) => { e.preventDefault(); e.stopPropagation(); toggleCategory(_letter, (item.substr(0, 1) === "-") ? item.substr(1, item.length) : item, true, (item.substr(0, 1) === "-") ? "exclude" : "include"); }} style={{ "fontWeight": "normal", "padding": "0px 4px", "lineHeight": "12px", "color": "#888", "marginRight": "3px", "maxWidth": "212px", "overflow": "hidden", "textOverflow": "ellipsis" }} closeIcon={<small><CloseOutlined style={{ "fontSize": "7px" }} /></small>} closable>
                                    <small>{getNameByKey(item, _letter)}</small>
                                </Tag>
                            )
                        })}
                    </div>
                </Col>
            </Row>
        )
    }

    const drawAllSelectedTags = () => {
        let count = 0;
        let ret = [];
        // PRESET 
        ret.push(<FilterPresetLabel setToolboxes={setToolboxes} toolboxes={toolboxes} header={header} path={path} getBrowse={getBrowse} setSearchFilters={setSearchFilters} searchFilters={searchFilters} />)
        if(searchFilters.hasOwnProperty("p") && searchFilters.p){
            count++; 
        }

        ret.push(Object.keys(searchFilters).map((key, _index) => {
            if (Array.isArray(searchFilters[key])) {
                return searchFilters[key].filter(i => i !== "AND").map((item, index) => {
                    if (getNameByKey(item, key) === "") {
                        return;
                    }
                    count++;
                    return (
                        <Tag key={_index.toString() + index.toString()} onClick={(e) => { e.preventDefault(); e.stopPropagation(); toggleCategory(key, (item.substr(0, 1) === "-") ? item.substr(1, item.length) : item, true, (item.substr(0, 1) === "-") ? "exclude" : "include", true); }} color={(item.substr(0, 1) === "-") ? "red" : "green"} onClose={(e) => { e.preventDefault(); e.stopPropagation(); toggleCategory(key, (item.substr(0, 1) === "-") ? item.substr(1, item.length) : item, true, (item.substr(0, 1) === "-") ? "exclude" : "include", true) }} className="filterTag bcg2" style={{ "color": "#888", "marginRight": "5px" }} closeIcon={<small><CloseOutlined style={{ "fontSize": "7px" }} /></small>} closable>
                            <small>{getNameByKey(item, key)}</small>
                        </Tag>
                    )
                })
            }
        }))

        if (searchFilters.k && searchFilters.k !== "keyword") {
            if (history.location.pathname.includes("/k/")) {
                ret.push(
                    <Tag className="bcg2" key={"keyword"} onClick={(e) => { 
                        setSearchFilters({ ...searchFilters, "k": "keyword" }); 
                        props.setCurrent(1); 
                        getBrowse(false, { ...searchFilters, "k": "keyword" }); 

                        let _loc = pathToObject(history.location.pathname);
                        if(_loc.hasOwnProperty("p")){
                            history.push({ pathname: path + objectToPath({ ...searchFilters, "k": "keyword", "p" : _loc.p }) }) 
                        } else {
                            history.push({ pathname: path + objectToPath({ ...searchFilters, "k": "keyword" }) }) 
                        }

                        }} color={"green"} onClose={(e) => { 
                            setSearchFilters({ ...searchFilters, "k": "keyword" }); 
                            props.setCurrent(1); 
                            getBrowse(false, { ...searchFilters, "k": "keyword" }); 

                            let _loc = pathToObject(history.location.pathname);
                            if(_loc.hasOwnProperty("p")){
                                history.push({ pathname: path + objectToPath({ ...searchFilters, "k": "keyword", "p" : _loc.p }) }) 
                            } else {
                                history.push({ pathname: path + objectToPath({ ...searchFilters, "k": "keyword" }) }) 
                            }
                            
                            }} style={{ "color": "#888" }} closeIcon={<small><CloseOutlined style={{ "fontSize": "7px" }} /></small>} closable>
                        <small>Search Type: {ucfirst(searchFilters.k)}</small>
                    </Tag>
                )
            }
        }

        // Search Term
        if (searchFilters.t) {

            let _temp = handleTermSlash(searchFilters.t, false);
            let _split = _temp.split(" ");
            count++;
            ret.push(_split.map(_itm => {
                if (!_itm) {
                    return;
                }
                return (
                    <Tag className="bcg2" key={_itm} onClick={(e) => { 
                        setSearchFilters({ ...searchFilters, "t": handleTermSlash(_split.filter(trm => trm !== _itm).join(" "), true) }); 
                        props.setCurrent(1); 
                        getBrowse(false, { ...searchFilters, "t": handleTermSlash(_split.filter(trm => trm !== _itm).join(" "), true) }); 
                        
                        let _loc = pathToObject(history.location.pathname);
                            if(_loc.hasOwnProperty("p")){
                                history.push({ pathname: path + objectToPath({ ...searchFilters, "p" : _loc.p, "t": handleTermSlash(_split.filter(trm => trm !== _itm).join(" "), true) }) }) 
                            } else {
                                history.push({ pathname: path + objectToPath({ ...searchFilters, "t": handleTermSlash(_split.filter(trm => trm !== _itm).join(" "), true) }) }) 
                            }

                        
                        
                        }} color={(_itm.substr(0,1) === "-") ? "red" : "green"} onClose={(e) => { 
                            setSearchFilters({ ...searchFilters, "o": 0, "t": handleTermSlash(_split.filter(trm => trm !== _itm).join(" "), true) }); 
                            props.setCurrent(1); 
                            getBrowse(false, { ...searchFilters, "t": handleTermSlash(_split.filter(trm => trm !== _itm).join(" "), true) }); 

                            let _loc = pathToObject(history.location.pathname);
                            if(_loc.hasOwnProperty("p")){
                                history.push({ pathname: path + objectToPath({ ...searchFilters, "p" : _loc.p, "o": 0, "t": handleTermSlash(_split.filter(trm => trm !== _itm).join(" "), true) }) }); 
                            } else {
                                history.push({ pathname: path + objectToPath({ ...searchFilters, "o": 0, "t": handleTermSlash(_split.filter(trm => trm !== _itm).join(" "), true) }) }); 
                            }

                            
                            
                            }} style={{ "color": "#888" }} closeIcon={<small><CloseOutlined style={{ "fontSize": "7px" }} /></small>} closable>

                        <small>{(listLabel.key == _itm) ? listLabel.value : _itm}</small>
                    </Tag>
                )
            }))
        }


        // Series
        if (searchFilters.z) {
            count++;
            let set = { ...searchFilters, "z": ""}
            ret.push(
                <Tag className="bcg2" key={"list"} onClick={(e) => { 
                    setSearchFilters(set); 
                    props.setCurrent(1); 
                    props.setTitle("Browse"); 
                    getBrowse(false, set); 
                    let _loc = pathToObject(history.location.pathname);
                    if(_loc.hasOwnProperty("p")){
                        history.push({ "p" : _loc.p, pathname: path + objectToPath(set) }) 
                    } else {
                        history.push({ pathname: path + objectToPath(set) }) 
                    }                    
                    }} color={"green"} onClose={(e) => { 
                        setSearchFilters(set); 
                        props.setCurrent(1); 
                        props.setTitle("Browse"); 
                        getBrowse(false, set); 

                        let _loc = pathToObject(history.location.pathname);
                        if(_loc.hasOwnProperty("p")){
                            history.push({ "p" : _loc.p, pathname: path + objectToPath(set) }) 
                        } else {
                            history.push({ pathname: path + objectToPath(set) }); 
                        }           
                        
                        }} style={{ "color": "#888" }} closeIcon={<small><CloseOutlined style={{ "fontSize": "7px" }} /></small>} closable>
                    <small>{searchFilters.z}</small>
                </Tag>
            )
        }




        // List
        if (searchFilters.x) {
            count++;
            let set = { ...searchFilters, "x": "", "v": "" }
            ret.push(
                <Tag className="bcg2" key={"list"} onClick={(e) => { 
                    setSearchFilters(set); 
                    props.setCurrent(1); 
                    props.setTitle("Browse"); 
                    getBrowse(false, set); 
                    let _loc = pathToObject(history.location.pathname);
                        if(_loc.hasOwnProperty("p")){
                            history.push({ "p" : _loc.p, pathname: path + objectToPath(set) }) 
                        } else {
                            history.push({ pathname: path + objectToPath(set) }) 
                        }         
                    }} color={(context.negated) ? "red" : "green"} onClose={(e) => { 
                        setSearchFilters(set); 
                        props.setCurrent(1); 
                        props.setTitle("Browse"); 
                        getBrowse(false, set); 
                        let _loc = pathToObject(history.location.pathname);
                        if(_loc.hasOwnProperty("p")){
                            history.push({ "p" : _loc.p, pathname: path + objectToPath(set) }) 
                        } else {
                            history.push({ pathname: path + objectToPath(set) }) 
                        }         
                                                }} style={{ "color": "#888" }} closeIcon={<small><CloseOutlined style={{ "fontSize": "7px" }} /></small>} closable>
                    <small>{context.name || searchFilters.x}</small>
                </Tag>
            )
        }


        // CLEAR ALL FILTERS
        ret.push(<Button key={"thing"} onClick={() => {
            if (searchFilters.hasOwnProperty("x")) {
                let _obj = {}
                //_obj.x = searchFilters.x;
                if (searchFilters.hasOwnProperty("v") && searchFilters.v !== "sequence") {
                    _obj.v = searchFilters.v;
                } else {
                    _obj.v = "";
                }
                _obj.o = 0;
                setSearchFilters(_obj);
                props.setCurrent(1);
                getBrowse(false, _obj);
                history.push({ pathname: path + objectToPath(_obj) });
            } else {
                setSearchFilters(pathToObject("/"));
                props.setCurrent(1);
                getBrowse(false, pathToObject("/"));
                history.push({ pathname: path, state: "bm_reset" + Math.random(100).toString() })
            }
        }}
        style={{ "height": "22px", "marginTop": "1px", "lineHeight": "12px", "marginRight": "6px", "padding" : "0px" }} type="link" size="small"><em>← Clear filters</em></Button>)
        if (count > 0) {
            return (
                <div style={{ "marginTop": "-20px", "marginBottom": "-5px" }}>
                    <br clear="all" />
                    <Divider dashed style={{ "margin": "8px 0px", "marginBottom": "10px" }} />
                    <div style={{ "minHeight": "29px", "maxHeight": "87px", "overflow": "auto" }}>
                        <Space>
                            {(!((searchFilters.hasOwnProperty("x") && (searchFilters.hasOwnProperty("x")))) && <>
                                {/* [TODO] */}
                                {/* <FilterPresets showselected toolboxes={toolboxes} header={header} path={path} getBrowse={getBrowse} setSearchFilters={setSearchFilters} searchFilters={searchFilters} /> */}
                            </>)}
                            <Space wrap size={[0, 5]}>
                                {ret}
                            </Space>
                        </Space>
                    </div>
                </div>
            );
        }
        return "";
    }

    const toggleCategory = (_letter, _key, concat = false, action = "label", go = false) => {

        let _loc = pathToObject(history.location.pathname);
        
        
        let value = (searchFilters.hasOwnProperty(_letter)) ? [...searchFilters[_letter]] : []
        switch (action) {
            case "label":
                value = [_key]
                break;
            case "include":
                if (value.includes(_key)) {
                    // remove
                    value = [...searchFilters[_letter]].filter(item => item !== _key);
                } else {
                    value.push(_key);
                }
                break;
            case "exclude":
                if (value.includes("-" + _key)) {

                    // remove
                    value = [...searchFilters[_letter]].filter(item => item !== "-" + _key);
                } else {
                    value.push("-" + _key);
                }
                break;
            default:
                value = [_key]
                break
        }
        if (action === "label") {
            value = [_key]
        }
        setSearchFilters({ ...searchFilters, [_letter]: value });
        props.setCurrent(1);
        if (action === "label" || go) {
            setVistoggle("close");
            let _f = { ...searchFilters, [_letter]: value }
            // reset pagination
            // if (_f.hasOwnProperty("l")) {
            //     delete _f.l;
            // }
            if (_f.hasOwnProperty("o")) {
                delete _f.o;
            }
            getBrowse(false, _f);

            if(_loc.hasOwnProperty("p")){
                history.push({ pathname: path + objectToPath({..._f, "p" : _loc.p}) })
            } else {
                history.push({ pathname: path + objectToPath(_f) })
            }
           
        }
        return;
    }

    const drawCategories = (_rows = [], _filter) => {
        let _required = [];
        let _negated = [];
        let _metafilter = false;
        if (metaSet.length > 0 && _filter.meta_filters.length > 0) {
            metaSet.forEach(item => {
                if (item.hasOwnProperty("negate") && item.negate) {
                    _negated = _negated.concat(item.filters);
                } else {
                    _required = _required.concat(item.filters);
                }
            })
            _metafilter = true;
        }
        return _rows.map((category, index) => {
            const {
                key = "",
                count = "",
                subfilter = []
            } = category;
            const name = category.name;
            if (_metafilter) {
                if (_required.length > 0 && !_required.includes(key)) {
                    return (<></>);
                }
                if (_negated.length > 0 && _negated.includes(key)) {
                    return;
                }
            }
            if (key === "--") {
                return (<div key={index} style={{ "marginRight": "15px" }}><Divider style={{ "margin": "0px", "pointerEvents": "none" }} plain><small style={{ "fontSize": "8px", "color": "#777", "letterSpacing": "1.5px" }}>{name.toUpperCase()}</small></Divider></div>)
            }
            let any_included = false;
            let any_excluded = false;
            if (searchFilters.hasOwnProperty(_filter.routing_char) && searchFilters[_filter.routing_char].length > 0) {
                if (searchFilters[_filter.routing_char][0].substr(0, 1) === "-") {
                    any_excluded = true;
                } else {
                    any_included = true;
                }
            }
            let is_included = (searchFilters.hasOwnProperty(_filter.routing_char) && searchFilters[_filter.routing_char].includes(key)) || false;
            let is_excluded = (searchFilters.hasOwnProperty(_filter.routing_char) && searchFilters[_filter.routing_char].includes("-" + key)) || false;
            let both = true;
            if (searchFilters.hasOwnProperty(_filter.routing_char) && searchFilters[_filter.routing_char].length > 0) {
                both = false;
            }
            return (
                <div key={index}>
                    <div style={{ "float": "right", "marginRight": "12px" }}>
                        <Space size={2}>
                            <div className={(any_included) ? "disabled" : ""}><CloseSquareFilled key={"exclude"} onClick={() => (any_included) ? () => { } : toggleCategory(_filter.routing_char, key, true, "exclude")} style={{ "color": (is_excluded) ? bestsellerColor : "#bbb", "opacity": (count > 0) ? "1" : "0.2", "pointerEvents": (count > 0) ? "auto" : "none" }} /></div>
                            <div className={(any_excluded) ? "disabled" : ""}><CheckSquareFilled key={"include"} onClick={() => (any_excluded) ? () => { } : toggleCategory(_filter.routing_char, key, true, "include")} style={{ "color": (is_included) ? successColor : "#bbb", "opacity": (count > 0) ? "1" : "0.2", "pointerEvents": (count > 0) ? "auto" : "none" }} /></div>
                        </Space>
                    </div>
                    <div className="c" onClick={() => toggleCategory(_filter.routing_char, key, false, "label")} style={{ "overflow": "hidden", "textOverflow": "ellipsis", "maxWidth": "150px", "whiteSpace": "nowrap", "cursor": "pointer", "opacity": (count > 0) ? "1" : "0.5", "pointerEvents": (count > 0) ? "auto" : "none" }}>
                        <small><div style={{ "color": "#999", "fontSize": "9px", "width": "25px", "display": "inline-block" }}>{formatCount(count)}</div> <span className="c">
                            <Tooltip mouseEnterDelay={0.5} title={(tips[key]) ? (tips[key]) : name}>{(is_included || is_excluded) ? <strong>{name}</strong> : name}</Tooltip>
                        </span> </small>
                    </div>
                    {(subfilter.length > 0 &&
                        subfilter.map((item, index) => {
                            is_included = (searchFilters.hasOwnProperty(_filter.routing_char) && searchFilters[_filter.routing_char].includes(item.key)) || false;
                            is_excluded = (searchFilters.hasOwnProperty(_filter.routing_char) && searchFilters[_filter.routing_char].includes("-" + item.key)) || false;
                            return (
                                <div key={index}>
                                    <div style={{ "float": "right", "marginRight": "12px" }}>
                                        <Space size={2}>
                                            <div className={(any_included) ? "disabled" : ""}><CloseSquareFilled onClick={() => (any_included) ? () => { } : toggleCategory(_filter.routing_char, item.key, true, "exclude")} style={{ "color": (is_excluded) ? bestsellerColor : "#bbb", "opacity": (item.count > 0) ? "1" : "0.2", "pointerEvents": (item.count > 0) ? "auto" : "none" }} /></div>
                                            <div className={(any_excluded) ? "disabled" : ""}><CheckSquareFilled onClick={() => (any_excluded) ? () => { } : toggleCategory(_filter.routing_char, item.key, true, "include")} style={{ "color": (is_included) ? successColor : "#bbb", "opacity": (item.count > 0) ? "1" : "0.2", "pointerEvents": (item.count > 0) ? "auto" : "none" }} /></div>
                                        </Space>
                                    </div>
                                    <Tooltip mouseEnterDelay={0.5} title={<small>{item.name}<span style={{ "color": "#999", "fontSize": "9px" }}>{formatCount(item.count)}</span></small>}>
                                        <div onClick={() => toggleCategory(_filter.routing_char, item.key, false, "label")} style={{ "overflow": "hidden", "textOverflow": "ellipsis", "maxWidth": "130px", "whiteSpace": "nowrap", "cursor": "pointer", "opacity": (item.count > 0) ? "1" : "0.5", "pointerEvents": (item.count > 0) ? "auto" : "none" }}>
                                            <small><div style={{ "color": "#999", "fontSize": "9px", "width": "25px", "display": "inline-block" }}>{formatCount(item.count)}</div> <span className="c">- {(is_included || is_excluded) ? <strong>{item.name}</strong> : item.name}</span> </small>
                                        </div>
                                    </Tooltip>
                                </div>
                            )
                        })
                    )}
                </div>
            )
        })
    }

    const drawHeader = (_filter = {}) => {

        const {
            exclude = false,
            title = "",
            routing_char = "r",
            selected_rows = [],
            rows = [],
        } = _filter;

        return (<>
            <div>
                <Row>
                    <Col flex={"auto"}><span className="c" style={{ "fontWeight": "600" }}>{title}</span></Col>
                    <Col flex={"140px"}>
                        <div style={{ "float": "right", "fontWeight": "normal", "color": "#ccc", "fontSize": "9px", "marginRight": "0px", "marginTop": "0px" }}>
                            <Space size={3}>
                                <div className="ant-radio-group-solid ant-radio-group-small">
                                    <Button onClick={(e) => toggleCategory(routing_char, "AND", false, "include")} disabled={!(searchFilters.hasOwnProperty(routing_char) && searchFilters[routing_char].length > 1)} type={(searchFilters.hasOwnProperty(routing_char) && searchFilters[routing_char].includes("AND")) ? "primary" : "default"} style={{ "borderRight": "none", "borderRadius": "2px 0 0 2px" }} className="tiny-radio" >
                                        <Tooltip mouseEnterDelay={0.5} title={<>Include All<br /><small>When selected, titles must match all included filters instead of any.</small></>}>
                                            <small>All</small>
                                        </Tooltip>
                                    </Button>
                                    <Button onClick={(e) => toggleCategory(routing_char, "AND", false, "include")} disabled={!(searchFilters.hasOwnProperty(routing_char) && searchFilters[routing_char].length > 1)} type={(searchFilters.hasOwnProperty(routing_char) && searchFilters[routing_char].includes("AND")) ? "default" : "primary"} style={{ "borderRadius": "0 2px 2px 0" }} className="tiny-radio" >
                                        <Tooltip mouseEnterDelay={0.5} title={<>Include Any<br /><small>When selected, titles must match at least one of the included filters instead of all.</small></>}>
                                            <small>Any</small>
                                        </Tooltip>
                                    </Button>
                                </div>
                                <Col flex={"25px"}>
                                    <Button style={{ "padding": "0px 7px", "marginTop": "0px" }} onClick={() => go()} disabled={(!changed)} size="small" type="primary" className="tiny-btn"><small>Go!</small></Button>
                                </Col>

                                <Button style={{ "marginTop": "0px" }} onClick={() => resetFilter(routing_char)} disabled={!(searchFilters.hasOwnProperty(routing_char) && searchFilters[routing_char].length > 0)} size="small" className="tiny-btn" type="primary"><small>Reset</small></Button>

                            </Space>
                        </div>
                        <br clear="all" />
                    </Col>
                </Row>
                <div className="shim" />

                <conditional.true value={(_filter.name !== "all")}>
                    <FilterAutocomplete searchFilters={searchFilters} name={_filter.name} filters={filters} setFilters={setFilters} />
                </conditional.true>
                <Divider dashed style={{ "margin": "10px 0px" }} />
            </div>

            <conditional.true value={(searchFilters.hasOwnProperty(routing_char) && searchFilters[routing_char].length > 0)}>
                {drawSelectedTags(selected_rows, routing_char, exclude)}
                <Divider dashed style={{ "margin": "8px 0px", "marginTop": "0px" }} />
            </conditional.true>
        </>
        )
    }

    const drawFilter = (_filter = {}) => {

        const {
            rows = [],
        } = _filter;
        return (
            <>
                <div style={{ "marginLeft": "5px" }}>{drawCategories(rows, _filter)}</div>
            </>
        )
    }

    const drawFilterBoxes = () => {
        return filters.map((filter, index) => {
            let align_left = (index > (filters.length - 3))
            return (
                <DropMenu alignLeft={align_left} block={block} vistoggle={vistoggle} setVistoggle={setVistoggle} onClose={go} key={index} 
                    label={<>{drawHeader(filter)} <Metafilters metaSet={metaSet} setMetaSet={setMetaSet} filter={filter} /> </>} 
                    count={filter.rows.length} 
                    active={(filter.selected_rows.filter(item => item !== "AND").length)} 
                    title={filter.title}>
                    <div style={{"width": "100%",  "maxHeight": "25vh", "overflow": "auto"}} >
                        <MagicFilters getBrowse={getBrowse} path={path} searchFilters={searchFilters} setSearchFilters={setSearchFilters} filter={filter} />
                        {drawFilter(filter)}
                    </div>
                </DropMenu>
            )
        })
    }

    const drawFilters = () => {
        return (
            // <conditional.true value={(filters.length > 0)}>
            <div>
                <div className="trans" style={{"padding": "10px 20px", "paddingBottom": "0px", "opacity": (loading) ? "0.5" : 1 }}>
                    <div style={{ "float": "right" }}>
                        <Space size={20}>
                            {display}
                        </Space>
                    </div>
                    <div style={{"display": "flex", "justifyContent": "stretch", "gap": "5px" }}>
                        <FilterPresets toolboxes={toolboxes} header={header} path={path} getBrowse={getBrowse} setSearchFilters={setSearchFilters} searchFilters={searchFilters} />
                        {drawFilterBoxes()}
                    </div>
                    <div>
                        {drawAllSelectedTags()}
                    </div>
                </div>
                <div className="afix"></div>
            </div>
            // </conditional.true>
        )
    }

    const draw = () => {
        if (display_mode === "calendar") {
            return <>
            
            
            {drawFilters()}<div className="shim" /><div className="shim" /></>
        }
        return (<Affix className="afix" 
                    style={{ "paddingBottom": "10px" }} 
                    offsetTop={(hasBanner) ? 85 : 55}>{drawFilters()}</Affix>)
    }

    return draw();
}