import { CloseOutlined, InfoCircleOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Divider, Form, Input, Layout, Modal, Radio, Row, Space, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ListCategories from "../../components/lists/ListCategories";
import Lists from "../../components/lists/Lists";
import LayoutHeader from "../../layouts/partials/LayoutHeader";
import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import Rep from "./Rep";

export default function MyReps() {

    const history = useHistory(); 
    const [selectedFilters, setSelectedFilters] = useState({ term: (history.location.state) ? history.location.state : "", filter: "all" });
    const [categories, setCategories] = useState({ selected: {}, menu: {} });
    const [results, setResults] = useState([]);

    const fetchAll = (_term = selectedFilters.term) => {
        let _sel = selectedFilters.filter
        let _obj = {
            my_reps: (_sel === "my_reps" || _sel === "all") ? true : false,
            approved: (_sel === "approved" || _sel === "all") ? true : false,
            pending: (_sel === "pending" || _sel === "all") ? true : false,
            declined: (_sel === "declined" || _sel === "all") ? true : false,
        }
        if (_term) {
            _obj.filter = _term;
        }
        apiCall("rep_control/getAll", _obj, (_status, _result) => {
            if (_status) {
                setResults(_result);
            }
        })
    }

    useEffect(fetchAll, [selectedFilters.filter, history.location.state]);

    const columns = [
        {
            title: <small>Name</small>, dataIndex: 'name', key: 'name', render: (e) => {
                return (<strong><a>{e}</a></strong>)
            }
        },
        { title: <small>Category</small>, dataIndex: 'category', key: 'category', width: "200px" },
        { title: <small>Email</small>, dataIndex: 'email', key: 'email', width: "300px" },
        { title: <small>City</small>, dataIndex: 'city', key: 'city', width: "100px" },
        { title: <small>Province</small>, dataIndex: 'province', key: 'province', width: "30px" },
        { title: <small>Country</small>, dataIndex: 'country', key: 'country', width: "80px" },
    ]

    const getStarted = () => {
        Modal.info({
            width: "650px", icon: <></>, title: "Getting started", content: <div style={{ "height": "calc(100vh - 300px)", "overflow": "auto", "paddingRight": "10px" }}>
                <Divider style={{ "margin": "15px 0px" }} />
                <p>Reps listed on this page have selected your store as one they service. Below their name is the Pubstock company (or companies) that they represent.</p>
                <p>If the rep you want to share with is not listed on this page, you need to contact them. Many reps may be familiar with how to start this connection process, otherwise feel free to have them contact Bookmanager, and we'll be more than happy to walk them through it.</p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p>Reps with a grey checkmark <nobr className="noInteract"><Checkbox checked disabled /></nobr> next to their name indicate that that they are sharing lists, titles, and/or catalogues with you. These reps do not do not have access to see your inventory info.</p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p>Reps with a green share <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#78c042"><strong><ShareAltOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> are sharing lists and catalogues with you; and you are sharing your store data with them. If you have a good working relationship with a rep, sharing your store's inventory info (sales, on hand, order quantities) can help them do a better job of ensuring that you are not missing important opportunities. <strong className="c">A rep can only see information on the titles that they represent.</strong> And only reps that have the green share icon can see your data.</p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p>Reps listed with an orange share <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#e77e21"><strong><ShareAltOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> have requested to be connected with your store and your inventory data, but need approval from you first. These reps are presently only able to share lists, titles, and/or catalogues with you. If you want to decline the share request, simply click the red X <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#f12a46"><strong><CloseOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> next to their name, and you can then click to "hide"  them from this list.</p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p>Reps listed with a purple share <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#8f3fe4"><strong><ShareAltOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> indicate that this request has been sent more than once. These reps are presently only able to share lists, titles, and/or catalogues with you. If you want to decline the share request, simply click the red X <nobr className="noInteract"><Tag style={{ "marginRight": "0px" }} color="#f12a46"><strong><CloseOutlined style={{ "color": "#fff" }} /></strong></Tag></nobr> next to their name, and you can then click to "hide"  them from this list.</p>
                <Divider dashed style={{ "margin": "15px 0px" }} />
                <p>Please contact <a href="mailto:mail@bookmanager.com">mail@bookmanager.com</a> | 250.763.4415 if you have any questions about sharing with your reps, and the data that is visible.</p>
            </div>
        })
    }

    const drawItems = () => {
        return results.map((item, index) => {
            return <Rep updateRow={updateRow} index={index} data={item} />
        })
    }

    const updateRow = (_item) => {
        let _update = [...results];
        let _index = _update.findIndex(itm => itm.contact_id === _item.contact_id);
        _update[_index] = _item;
        setResults(_update);
    }

    return (
        <>
            <Layout style={{ "backgroundColor": "#fff" }}>
                <LayoutHeader
                    title="My Reps"
                    filters={
                        <Form size="small" layout="vertical">
                            <Space>
                                <Form.Item initialValue={(history.location.state) ? history.location.state : ""} label={<small>Keyword Search</small>} name="keyword_search">
                                    <Input.Search onSearch={(e) => fetchAll(e)} allowClear enterButton placeholder="Search for a rep" style={{ "width": "350px" }} />
                                </Form.Item>
                                <Form.Item label={<small>Show</small>}>
                                    <Radio.Group onChange={(e) => setSelectedFilters({ ...selectedFilters, "filter": e.target.value })} defaultValue={"all"} buttonStyle="solid">
                                        <Radio.Button value="all"><small>All</small></Radio.Button>
                                        <Radio.Button value="my_reps"><small>My Reps</small></Radio.Button>
                                        <Radio.Button value="pending"><small>Share requested</small></Radio.Button>
                                        <Radio.Button value="approved"><small>Shared</small></Radio.Button>
                                        <Radio.Button value="declined"><small>Share declined</small></Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label={<small>&nbsp;</small>}>
                                    <Button type="text" onClick={() => getStarted()}><small><InfoCircleOutlined /> Getting started</small></Button>
                                </Form.Item>
                            </Space>
                            <br clear="both" />
                        </Form>
                    }
                />
                <Layout.Content className="layoutHeader" style={{"padding": "0px" }} >
                    <div style={{ "padding": "0px 20px", "marginTop": "-6px", "marginBottom": "-6px" }}>
                        <>
                        <div style={{"padding" : "20px"}}>
                        {drawItems()}
                        </div>
                        </>
                    </div>
                </Layout.Content>
            </Layout>
        </>
    )
}